import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { GlobalComponent } from 'src/app/global/global.component';
import { SeoService } from 'src/app/service/seo.service';

@Component({
  selector: 'login-budget2021',
  templateUrl: './budget2021.component.html',
  styleUrls: ['./budget2021.component.scss']
})
export class Budget2021Component implements OnInit {

  hostName = GlobalComponent.hostName;

  constructor(private SEOService:SeoService) { }

  ngOnInit(){

  this.SEOService.updateCanonicalUrl(this.hostName + '/blog/Budget-2021-What-does-it-have-in-store-for-the-Manufacturing-Industry');


  }


}
