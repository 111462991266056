import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { GlobalComponent } from '../global/global.component';
import { SeoService } from '../service/seo.service';

@Component({
  selector: 'login-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  
  hostName = GlobalComponent.hostName;

  constructor(private SEOService:SeoService) { }

  ngOnInit(){

  this.SEOService.updateCanonicalUrl(this.hostName + '/blog');


  }
  scroll(el: HTMLElement){
    el.scrollIntoView({behavior: 'smooth'})
  }

}
