import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalComponent } from '../global/global.component';
import { SeoService } from '../service/seo.service';

@Component({
  selector: 'login-forbuyer',
  templateUrl: './forbuyer.component.html',
  styleUrls: ['./forbuyer.component.scss']
})
export class ForbuyerComponent implements OnInit {
  
  hostName = GlobalComponent.hostName;

  constructor(private router: Router,private SEOService:SeoService) { }

  ngOnInit(){
    this.SEOService.updateCanonicalUrl(this.hostName + '/buyers-benefits');
  }

  signup() {
    this.router.navigate(['/signup']);
  }
}



