import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { GlobalComponent } from 'src/app/global/global.component';
import { SeoService } from 'src/app/service/seo.service';

@Component({
  selector: 'login-vds',
  templateUrl: './vds.component.html',
  styleUrls: ['./vds.component.scss']
})
export class VdsComponent implements OnInit {

  hostName = GlobalComponent.hostName;

  constructor(private SEOService:SeoService) { }

  ngOnInit(){

  this.SEOService.updateCanonicalUrl(this.hostName + '/blog/Vendor-Development-strategic-sourcing');


  }

}
