import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { GlobalComponent } from 'src/app/global/global.component';
import { SeoService } from 'src/app/service/seo.service';

@Component({
  selector: 'login-supplychain',
  templateUrl: './supplychain.component.html',
  styleUrls: ['./supplychain.component.scss']
})
export class SupplychainComponent implements OnInit {

  hostName = GlobalComponent.hostName;

  constructor(private SEOService:SeoService) { }

  ngOnInit(){

  this.SEOService.updateCanonicalUrl(this.hostName + '/blog/4-Tips-To-Optimize-Your-Supply-Chain-Cost');


  }

}
