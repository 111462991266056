
<div class="banner-1 cover-image bg-background1" data-image-src="assets/images/banners/banner4.jpg">
    <login-home-navigation></login-home-navigation>
            
          
           
</div>
<section class="sptb innerpage-margin" #blog_fy>
    <div class="container">

        <div class="row">
        
            <div class="col-xl-12 col-lg-8 col-md-12">
                <!--Itemsd lists-->
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h2 class="fs-24">First Year as a co-founder !!</h2>
								<a class="fa fa-arrow-left" routerLink = "/blog" > Go Back to Blog List</a>
								<div class="item7-card-desc d-flex mb-5">
									<a href="#" class="text-muted"><i class="fa fa-calendar-o mr-2"></i>AUGUST 21, 2021</a>
									
									
								</div>
								<div>
								<img src="assets/images/media/firstyear.webp" alt="img" width="420">
								<p>A year ago we launched our bootstrapped platform GetYourVendor for Indian custom manufacturing companies. 
									It feels just like yesterday when I was writing our first specification document and the first line of code. 
									It was a huge decision to leave a comfortable IT job and jump into entrepreneurship with a vague idea to provide Indian manufacturers a global platform.</p>
									<h3>Milestones of our journey</h3>
									<p><strong>Company</strong></p>	
									<p>The idea to form Indisourcing Solutions Pvt. Ltd. with like-minded people was to deliver the best solution for Indian manufacturing companies. I must admit that we had a false start on technology and suppliers but we quickly turned the tables around to release MVP. </p>
									<p>We identified the gap between the vendor and buyer this became the USP of the portal. </p>
									<ul>
										<li>• Online search for the specific facility (Manufacturing Setup) </li><br>
										<li>• Online vendor development process</li><br>
										<li>• Virtual showroom for Indian suppliers to reach global buyers</li><br>
										<li>• One-click RFQ access</li>
									</ul>
									
									<p><strong>Team, Associates, and Customers</strong></p>
									<p>We formed a talented and passionate team to start our journey. Today we are able to manage more than 1000 supplier’s requirements and have sourced more than 100 RFQ’s. I must mention my partners Girish and Mihir who have been instrumental in executing the plans to achieve our goals. Girish deserves a special mention for his technical knowledge and passion for GetYourVendor which helped us to create a substantial footprint in the market.</p>

									<p><strong>We are grateful to:</strong></p>
									<p>Mr. Jagdish Maheta (Director, ACG Metalcrafts) and Mr. Nishikant Ahire (CMD AMT Group) for launching our platform in the manufacturing industry.</p>
									<p>Anant Waykole (Stead Solve Design & Manufacturing Solutions LLP) 1st customer who believed in our concept.</p>
									<p>Neha Thakur (CEO, Digital Elixir It Solutions) as our Digital Marketing partner</p>
									<p>Vinil and Prachi Pradhan (Pravi Design) as our development partners.</p>
									<p>Meet Thosar for her timely help to select the latest technology platform.</p>
									<p>Mr. Satish Varade (Technical Consultant) for being our first associate.</p>
									<p>We would like to express our gratitude to all our customers, associates, and well-wishers who believed in us and helped us to reach this milestone.</p>
									<p>A big shout out to our young and passionate team for their outstanding work!</p>

									<p><strong>Startup Life</strong></p>
									<p>As the co-founder and CTO, I faced various challenges starting from setting up the technical infrastructure to sales/marketing strategies. I have spent sleepless nights developing new features. It is more than a full-time job when you are building a product, people, and process. My wife made sure that I get time to focus 100% on GetYourVendor by providing me freedom from family responsibilities. I missed my daughter’s important life milestones like her first step, her 1st word. But now when I look back it gives me immense satisfaction with the progress that we made as a company despite all the challenges.</p>
									
									<p><strong>Future</strong></p>
									<p>The future is very exciting and challenging. Against all odds, we believe in our vision to provide the best global platform for Indian manufacturing companies.</p>
									<p>So let’s buckle up and see India’s fastest-growing vendor/buyer platform take the world’s modern manufacturing to the next level.</p>
									<p>Visit us: <a href="https://www.getyourvendor.com">www.getyourvendor.com</a></p>

 									<p>“Success is not final, failure is not fatal, it is the courage to continue that counts”</p>
                                    <p><a href="/signup" ><button class="fbutton">SignUp Now</button></a></p>
                                </div>
								<p> <a href="/blog" > << Go Back to Blog Home</a> </p>
							</div>
						</div>
                    </div>
                   
                </div>
                <div class="center-block text-center">
                </div>
                <!--/Itemsd lists-->
            </div>
            <!--Right Side Content-->
         
            <!--/Right Side Content-->
        </div>
    </div>
</section><!--/Section-->
<login-home-footer></login-home-footer>