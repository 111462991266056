<div class="banner-1"  >
    <login-home-navigation></login-home-navigation>
	<!-- <login-home-new></login-home-new> -->
    <!-- <app-home-search-and-filters></app-home-search-and-filters> -->
    <p>&nbsp;</p>
</div>

<section class="sptb" style="background-color:white;">
<div class="container" >

    <h1 style="text-transform: uppercase;"><b>India’s fastest growing Manufacturing B2B Marketplace</b></h1>
    <div class="desktop_device">
        <div class="row">
        
        
            <div class="col-lg-6 col-md-6 col-sm-6" style="align-items: flex-end;" >
                <!-- <div id=container> -->
                    
                    <div id=container1>
                        FIND BEST 
                        <div id=flip>
                        <div><div>Machining</div></div>
                        <div><div>Casting</div></div>
                        <div><div>Fabrication</div></div>
                        </div>
                    Custom Manufacturing Facility
                    </div>

                
            </div>
            
            <div class="col-lg-6 col-md-6 col-sm-6" style="padding-top:50px;">
            
        
                    <div class="fade-in"><p class="save pad1">SAVE</p></div>
                    <div class="fade-out"><p class="save pad2">TIME</p></div>
                    <div class="fade-in" ><p class="save pad3">MONEY </p></div>
                    <div class="fade-out"><p class="save pad4">RESOURCES </p></div>
                    <!-- <div class="fade-in">
                        TEXT
                    </div> -->

             </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6"  >
            
                <div class="divp1"><div class="divanimate"><span style="color: #f5c148;">2</span> Million Parts Delivered</div></div>
                <div class="divp2" ><div class="divanimate1"><span style="color: #f5c148;">5000+</span> Verified Vendors</div></div>
                <div class="divp3" ><div class="divanimate2"><span style="color: #f5c148;">200+</span> Verified Buyers</div></div>
            

            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 " >
            
                <!-- <p class="zoom-in-out-box">20CR WORTH RFQ SOURCED</p> -->
                <p style="padding-left: 50%;"><img  class="rounded-corners" (click)="openDialog()"  src="assets/images/media/Freedemo.gif" alt="Free Demo" width="100%" height="100%"></p>
            </div>

        </div>
        </div>
    </div>    

    <div class="mobile_device_425px">
        <div class="col-lg-6 col-md-6 col-sm-6"  >
                
                <div id=container1>
                    FIND BEST 
                    <div id=flip>
                    <div><div>Machining</div></div>
                    <div><div>Casting</div></div>
                    <div><div>Fabrication</div></div>
                    </div>
                Custom Manufacturing Facility
                </div>

                <div class="fade-in"><p class="save pad1">SAVE TIME MONEY RESOURCES</p></div>
                <!-- <div class="fade-out"><p class="save pad2">TIME</p></div>
                <div class="fade-in" ><p class="save pad3">MONEY </p></div>
                <div class="fade-out"><p class="save pad4">RESOURCES </p></div> -->
        </div>
        
        <div class="col-lg-6 col-md-6 col-sm-6" >
        
    
            <div class="divp1"><div class="divanimate"><span style="color: #f5c148;">2</span> Million Parts Delivered</div></div>
            <div class="divp2" ><div class="divanimate1"><span style="color: #f5c148;">5000+</span> Verified Vendors</div></div>
            <div class="divp3" ><div class="divanimate2"><span style="color: #f5c148;">200+</span> Verified Buyers</div></div>
        

         </div>
    </div>

    <div class="row" >
        <div class="col-lg-12 col-md-12 col-sm-12">
          <marquee scrollamount="12"><p class="animate">&#x275D;We Connect Buyers and Suppliers in <a href="/blog/Custom-Manufacturing-The-USP-of-distinctive-brands" target="_blank" style="color:red;">Custom Manufacturing</a> &#x275E;</p></marquee>
        </div>    
    </div>
    
  
</section>


<section class="sptb innerpage-margin cat1" style="background-color:antiquewhite;" >
    <h2>
       Custom Manufacturing Categories, we cater more than <span style="color: red;">25+ categories</span> 
    </h2>
       
    <div class="desktop_device">
     <mat-tab-group dynamicHeight style="align-items: center; padding-top: 3%;" >

       <mat-tab label="Machining">
            <div class="desktop_container">
                <div class="row">
                    <div class="col-md-3 col-sm-3" style="align-items: left;" >
                        <img src="assets/images/media/home/category/machining.webp" alt="machining" title="Machining" width="100%" height="100%" >

                    </div>
                    <div class="col-md-3 col-sm-3" style="text-align: left;">
                        <p></p>
                        <p><a href="/blog/Custom-Manufacturing-The-USP-of-distinctive-brands">Machining</a></p>
                        <p>Precision Machining</p>
                        <p>Grinding</p>
                        <p>Dies and Tools</p>
                        <p>EDM and Wire cut</p>
                        <p>Plasma Cutting</p>
                        <p>Laser Cutting</p>
                    </div>
                    <div class="col-md-3 col-sm-3 " style="text-align: left;">
                        <p></p>
                        <p>Water Jet Cutting</p>
                        <p>3D Printing</p>
                        <p>Presses</p>
                        <p>Gear Making</p>
                        <p>Traub</p>
                        <p>Milling</p>
                        <p>Lathe</p>
                    </div>
                    <div class="col-md-3 col-sm-3 " style="text-align: left;" width="100%" height="100%">
                        <p></p>
                        <p>Drilling</p>
                        <p>Radial Drilling</p>
                        <p>Surface Grinding</p>
                        <p>Cylinder Grinding</p>
                        <p>Machining Center</p>
                        <p>Turning Center</p>

                    </div>
                </div>    
            </div>
        </mat-tab>
        <mat-tab label="Forging">
            <div class="desktop_container">
                <div class="row">
                    <div class="col-md-3 col-sm-3 ">
                    <img src="assets/images/media/home/category/forging.webp" alt="forging" title="Forging" width="100%" height="100%" >
                    </div>
                    <div class="col-md-3 col-sm-3 " style="text-align: left;">
                        <p></p>
                        <p>Impression Die Forging</p>
                        <p>Cold Forging</p>
                        <p>Open Die Forging</p>
                        <p>Seamless Rolled Ring Forging</p>
                        <p>Hot Forging</p>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Casting">
            <div class="desktop_container">
                <div class="row">
                    <div class="col-md-3 col-sm-3">
                         <img src="assets/images/media/home/category/casting.webp" alt="casting" title="Casting" width="100%" height="100%" >
                    </div>
                   
                    <div class="col-lg-3 col-md-3 col-sm-1" style="text-align: left;">
                        <p></p>
                        <p> Ferrous Casting</p>
                        <p> Sand Casting</p> 
                        <p> Investment Casting</p>
                        <p> Die Casting</p>
                        <p> Low Pressure Casting</p>
                        <p> Centrifugal Casting</p>
                       
                    </div>
                    <div class="col-md-3 col-sm-3" style="text-align: left;">
                        <p></p>
                        <p> Gravity Die Casting</p>
                        <p> Non Ferrous Casting</p>
                        <p> Vacuum Die Casting</p>
                        <p> Squeezing Die Casting</p>
                        <p> Lost Foam Casting</p>
                        <p> Continual Casting</p>
                           
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab  label="Fabrication"> 
            <div class="desktop_container">
                <div class="row">
            <div class="col-md-3 col-sm-3">
              <img src="assets/images/media/home/category/sheetmetal.webp" alt="sheetmetal" title="Fabrication" width="100%" height="100%" >
            </div>
            <div class="col-md-3 col-sm-3" style="text-align: left;">
                <p></p>
                <p> Panel Fabrication </p>
                <p> Light Fabrication </p>
                <p> Sheet Metal Fabrication </p> 
               
                   
            </div>
            <div class="col-md-3 col-sm-3" style="text-align: left;">
                <p></p>
                <p> Stainless Steel Fabrication </p>
                <p> Heavy Fabrication </p>
                <p> Tube/Chassis Fabrication </p>
                   
            </div>
        </div>
        </div>
        </mat-tab>
        <mat-tab  label="Heat Treatment"> 
            <div class="desktop_container">
                <div class="row">
                    <div class="col-md-3 col-sm-3 ">
                        <img src="assets/images/media/home/category/sheetmetal.webp" alt="Heat Treatment" title="Heat Treatment" width="100%" height="100%">
                    </div>
                    <div class="col-md-3 col-sm-3" style="text-align: left;">
                        <p></p>
                        <p>Heat Treatment</p>
                        <p>Normalizing</p>
                        <p>Case Hardening</p>
                        <p>Nitriding</p>
                        <p>Induction Hardening</p>
                        <p>Stress Relieving</p>
                        <p>Aging</p>
                        <p>Black Anodizing</p>
                    </div>
                    <div class="col-md-3 col-sm-3 " style="text-align: left;">
                        <p></p>
                        <p>Silver Anodizing</p>
                        <p>Color Anodizing</p>
                        <p>Nickel Plating</p>
                        <p>Nickel Chrome Plating</p>
                        <p>Hard Chrome Plating</p>
                        <p>Electroless Nickel Plating</p>
                        <p>Buffing</p>
                        <p>Shot Blasting</p>
                    </div>
                    <div class="col-md-3 col-sm-3 " style="text-align: left;"> 
                        <p></p>
                        <p>Painting</p>
                        <p>Powder Coating</p>
                        <p>Balzer Coating</p>
                        <p>Plasma Coating</p>
                        <p>Teflon Coating</p> 
                        <p>Zinc Plating</p>
                        <p>Flash Plating</p>
                    </div>
                   
                </div>
            </div>
        </mat-tab>
        <mat-tab  label="Electical/Electronics"> 
            <div class="desktop_container">
                <div class="row">
                    <div class="col-md-3 col-sm-3">
                        <img src="assets/images/media/home/category/electrical.webp" alt="Electical/Electronics" title="Electical" width="100%" height="100%" >
                    </div>
                   
                    <div class="col-md-3 col-sm-3 " style="text-align: left;">
                        <p></p>
                        <p>Programming</p>
                        <p>Automation</p>
                        <p>Wire Harnessing</p>
                        <p>Panel Building</p>
                        
                       
                    </div>
                    <div class="col-md-3 col-sm-3 " style="text-align: left;">
                        <p></p>
                        <p>PCB Design</p>
                        <p>EV Battery</p>
                        <p>Computer </p>
                        <p>Control Devices</p>
                    </div>
                    
                </div>
            </div>
        </mat-tab>
        <mat-tab  label="Packaging"> 
            <div class="desktop_container">
                <div class="row">
                    <div class="col-md-3 col-sm-3 ">
                        <img src="assets/images/media/home/category/packaging.webp" alt="Packaging" title="Packaging" width="100%" height="100%">
                    </div>
                   
                    <div class="col-md-3 col-sm-3" style="text-align: left;">
                        <p></p>
                        <p>Corrugated Boxes</p>
                        <p>Plastic Boxes</p>
                        <p>PET Containers</p>
                        
                       
                        <p>Foil Sealed Bags</p>
                    </div>
                    <div class="col-md-3 col-sm-3 " style="text-align: left;">
                        <p></p>
                        
                        <p>Paperboard Boxes</p>
                        <p>Rigid Boxes</p>
                        <p>Poly Bags</p>
                    </div>
                    
                </div>
            </div>
        </mat-tab>
        <mat-tab  label="All"> 
            <div class="desktop_container">
                <div class="row">
                    <div class="col-md-3 col-sm-3 ">
                        <p></p>
                        <p>MACHINING</p>
                        <p>CASTING</p>
                        <p>FORGING</p>
                        <p>ASSEMBLY/SUB-ASSEMBLY</p>
                        <p>FABRICATION</p>
                    </div>
                    <div class="col-md-3 col-sm-3 ">
                        <p></p>
                        <p>FABRICATION</p>
                        <p>HEAT PROCESS</p>
                        <p>TESTING & CALIBRATION</p>
                        <p>CONSULTING</p>
                        <p>PACKAGING</p>
                    </div>
                    <div class="col-md-3 col-sm-3 ">
                        <p></p>
                        <p>ELECTRICAL</p>
                        <p>ELECTRONICS</p>
                        <p>PLASTIC</p>
                        <p>RUBBER</p>
                        <p>SEALS</p>
                    </div>
                    <div class="col-md-3 col-sm-3">
                        <p></p>
                        <p>3D-PRINTING</p>
                        <p>ROBOTICS</p>
                        <p>AUTOMATION</p>
                        <p>SPM</p>
                        <p>RAW MATERIAL</p>
                    </div>
                   
                    
                </div>
            </div>
        </mat-tab>
  
    </mat-tab-group>
    </div>

    <div class="mobile_device_425px">
      <div class="col-md-3 col-sm-3">
        <p>MACHINING</p>
       <p>FABRICATION</p>
       <p>CASTING</p>
       <p>FORGING</p>
       <p>ELECTRICAL</p>
       <p>ELECTRONICS</p>

      </div>
        
    </div>
   
</section>




<section class="sptb innerpage-margin sec2" style="background-color:white" >

    <div class="container">
       
        <div class="row">
        <div class="col-md-5 col-sm-5 section2-body">
            
            <h2 style="text-align: left;padding-bottom: 2%;">BUYER'S BENEFIT's</h2>
            <p>&#10004; Receive Quick Quotations </p>
            <p>&#10004; Protect Drawings (Digital NDA)</p>
            <p>&#10004; Get Specific Category Suppliers </p>
            <p>&#10004; Free Vendor Assessment</p>
            <p>&#10004; Easy Sourcing</p>
            <p><a style="color:red" href="/buyers-benefits">Find More Benefits..</a></p>
           
            
        </div>
        <div class="col-md-2 col-sm-2"></div>
        <div class="col-md-5 col-sm-5 ">
           <a href="/buyers-benefits"> <img src="assets/images/media/home/category/buyer.webp" alt="buyers benefits" width="100%" height="100%"></a>
           <p style="align-items: center;"><a href="login/signup1" ><button class="btn-reg">Create RFQ Now</button></a> </p>
        </div>
        <!-- <div class="col-md-2 col-sm-2" >
            <p class=""><a href="/signup" ><button class="btn-reg">Create RFQ Now</button></a> </p>
        </div> -->
    </div> 
    </div>
    <div class="container" style="padding-top: 2%;" >
                
        <h2>&#x275D;Our Buyers are saving more than 20% cost using this platform &#x275E;</h2>
    </div>
    </section>
    <section class="sptb innerpage-margin sec3" style="background-color:antiquewhite;" >
        <div class="container">
            <!-- <div class="container" >
                
                <h2>&#x275D;Our Buyers are saving more than 20% cost using this platform &#x275E;</h2>
            </div> -->
            <div>
                <div class="row card-title " style="text-align: left;">
        
                <div class="col-md-5 col-sm-5 section2-body" >
                    <p>&#10148;<a href="login/signup1">SignUp</a> Company in 3 Steps</p>
                    <p>&#10148;Publish RFQ's</p>
                    <p>&#10148;Receive Instant Quotations </p>
                    <p>&#10148;View Vendor Assessment Report  </p>
                    <p>&#10148;Finalize Vendor</p>
                    <p>&#10148;Receive finished Jobs </p>
                    <!-- <p><a href="/signup" ><button class="fbutton">Start Now</button></a> </p> -->
                </div>
                <div class="col-md-5 col-sm-5" >
                    <img src="assets/images/media/home/category/buyerproc.webp" alt="buyer" width="90%" height="100%" >
                </div>
                <div class="col-md-2 col-sm-2">
                    <h2 class="spl" >HOW DOES IT WORK FOR BUYER</h2>
                </div>

               
            </div>
            </div>
           
        </div>
        <div class="container" style="padding-top: 1%;">
            <div>
        
                <h2>REGISTERED BUYER NETWORK</h2>
            </div>
            <div class="row" style="padding-top: 2%;">
              
                    <div class="col-md-2 col-sm-3 center"  ><img src="assets/images/media/buyers/webp/b2.webp" title="sap" alt="sap" width="100%" height="100%" >  </div>
                    <div class="col-md-2 col-sm-3 center" ><img src="assets/images/media/buyers/webp/b3.webp"  title="glatt" alt="glatt" width="100%" height="100%">  </div>
                    <div class="col-md-2 col-sm-3 center" ><img src="assets/images/media/buyers/webp/b9.webp"  title="sansera" alt="sansera" width="100%" height="100%">  </div>
                    <div class="col-md-2 col-sm-3 center" ><img src="assets/images/media/buyers/webp/b10.webp" title="ats" alt="ats" width="100%" height="100%">  </div>
                    <div class="col-md-2 col-sm-3 center"><img src="assets/images/media/buyers/webp/b8.webp"   title="bfw" alt="bfw" width="100%" height="100%" >  </div>
                    <div class="col-md-2 col-sm-3 center"><img src="assets/images/media/buyers/webp/b6.webp"   title="praj" alt="praj" width="100%" height="100%" >  </div>
                </div>
        
           
        
        </div>
        
        </section>



   
    <section class="sptb innerpage-margin sec3" style="background-color:white;" >
        <!-- <div class="row"> -->
        <div class="container">
               
           
     <div class="row">
       
        <div class="col-md-6 col-sm-6 " >
         <a href="/vendors-benefits"><img src="assets/images/media/home/category/vendor.webp" alt="vendor benefits" width="100%" height="90%" ></a>
         <p style="align-items: center;"><a href="login/signup1" ><button class="btn-reg">Send Quote Now</button></a> </p>
        </div>
        <div class="col-md-6 col-sm-6 section2-body">
            <h2 style="text-align: left;">VENDOR'S BENEFIT'S</h2>
            <p>&#10004; Access to unlimited RFQ's without any Vendor Code</p>
            <p>&#10004; Be visible to global Buyer</p>
            <p>&#10004; Access to Buyer without any middle-man</p>
            <p>&#10004; Equal opportunity, irrespective of size & location </p>
            <p>&#10004; Free Vendor assessment report </p>
            <p><a href="/vendors-benefits" style="color: red">Find More Benefits..</a></p>
          
        </div>
        <!-- <div class="col-md-2 col-sm-2">
            <p class=""><a href="/signup" ><button class="btn-reg">Send Quote Now</button></a> </p>
        </div> -->
   </div>
</div>


</section>


<section class="sptb innerpage-margin sec2" style="background-color:antiquewhite;" >
    <div class="container">
        
        <div class="row card-title " style="text-align: left;">
            <div class="col-md-5 col-sm-5">
                <img src="assets/images/media/home/category/vendorproc.webp" alt="vendor" width="90%" height="100%">
            </div>
            <div class="col-md-5 col-sm-5 section2-body" >
               
                
                <p>&#10148;<a href="login/signup1">SignUp</a> Company in 3 Steps</p>
                <p>&#10148; Unlimited access to specific Category RFQ Drawings</p>
                <p>&#10148; Send Best Quotation directly to buyer</p>
                <p>&#10148; Track Quotations and RFQ's </p>
                <p>&#10148; Close the deal & Receive Payment</p>
               
                
            </div>
            <div class="col-md-2 col-sm-2">
                <h2 class="spl">HOW DOES IT WORK FOR VENDOR</h2>
            </div>
            
        </div>
        
    </div>
    
    </section>
    <section class="sptb innerpage-margin" style="background: radial-gradient(300px 300px ellipse at 29% 50%,#323232,#3b3b3b,#191a1c);">
      <div class="conainter spl">
       <h2 style="color: white;">WHY US?</h2>
      </div>
   
        <div class="row why" style="padding-top: 5%;">
            <div class="col-md-3 col-sm-3">
                <p><i class="fa fa-briefcase fa-5x" style="color: red; font-size: 36px; "></i></p><p style="text-transform: uppercase; text-shadow:2px 2px 2px rgb(0 0 0 / 40%); color: white;">We are saving 20-25% Marketing cost</p>
            </div>
            <div class="col-md-3 col-sm-3">
                <p><i class="fa fa-certificate fa-5x" style="color: red;font-size: 36px;"></i></p><p style="text-transform: uppercase;text-shadow:2px 3px 3px rgb(0 0 0 / 40%) ;color: white;"> 5000+ Vendor Assessment Reports </p>
            </div>
            <div class="col-md-3 col-sm-3">
                <p><i class="fa fa-user fa-5x" style="color: red;font-size: 36px;text-transform: uppercase;"></i></p><p style="text-transform: uppercase;text-shadow:2px 3px 3px rgb(0 0 0 / 40%); color: white;">4 Million Visitors</p>
            </div>
            <div class="col-md-3 col-sm-3">
                <p><i class="fa fa-smile-o fa-5x" style="color: red; font-size: 36px;text-transform: uppercase;"></i></p><p style="text-transform: uppercase;text-shadow:2px 3px 3px rgb(0 0 0 / 40%); color: white;">58% RFQ Converted</p>
            </div>

        </div>
        <div class="row why" style="padding-top: 5%;">
            <div class="col-md-3 col-sm-3">
                <p><i class="fa fa-refresh fa-5x" style="color: red; font-size: 36px; "></i></p><p style="text-transform: uppercase; text-shadow:2px 3px 3px rgb(0 0 0 / 40%); color: white;">40% clients opted renewal </p>
            </div>
            <div class="col-md-3 col-sm-3">
                <p><i class="fa fa-phone-square fa-5x" style="color: red;font-size: 36px;"></i></p><p style="text-transform: uppercase;text-shadow:2px 3px 3px rgb(0 0 0 / 40%); color: white;"> 24X7 Customer Service </p>
            </div>
            <div class="col-md-3 col-sm-3">
                <p><i class="fa fa-globe fa-5x" style="color: red;font-size: 36px;text-transform: uppercase;"></i></p><p style="text-transform: uppercase;text-shadow:2px 3px 3px rgb(0 0 0 / 40%); color: white;">Global Platform</p>
            </div>
            <div class="col-md-3 col-sm-3">
                <p><i class="fa fa-database fa-5x" style="color: red; font-size: 36px;text-transform: uppercase;"></i></p><p style="text-transform: uppercase;text-shadow:2px 3px 3px rgb(0 0 0 / 40%); color: white;">Data Security</p>
            </div>

        </div>


     
        <!-- <div class="container" style="text-align:center;">

            <p class="section-title" style="color: black">WHY US?</p>
        </div>
        <div class="container section-body" style="text-align: center;">

            <p class="fa fa-laptop"> Digitally secured platform </p> <br>
            
            <p class="fa fa-industry"> Multiple Vendors, Buyers, and several business opportunities across Globe</p> <br>
            <p class="fa fa-globe">	24X7 Business Opportunities</p> <br>
            <p class="fa fa-money">	Economical Service Charges</p> <br>
            <p class="fa fa-briefcase">	Ease of doing business</p> <br>
            <p class="fa fa-low-vision"> Greater visibility</p><br>
        </div> -->
     

    </section>
	

    <section class="sptb innerpage-margin seclast" style="background-color: white">

        <div class="container">
            <div style="text-align: center;">
                    <h2>CUSTOMER TESTIMONIAL </h2>
                    
                </div>
        </div>     
        <div class="wrapper">
            <div class="box">
            <i class="fa fa-quote-left quote"></i>
            <p>We appreciate your sincere efforts for giving us business opportunities with many clients under one platform.
                Because your efforts & support, we able grab new business around 25 lakhs per month till now.
                We are thankful for you for providing such platform & looking forward to have other business opportunities through your site <a href="">Getyourvendor.com </a>
                </p>
            <div class="content">
                <div class="info">
                <div class="name">Mr.Jagannath Ramdasi</div>
                <div class="job">Business Development Manager</div>
                <div class="job">Kirdak Autocom Pvt Ltd</div>
                <div class="stars">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                </div>
                </div>
                <div class="image">
                <img src="assets/images/media/home/review/ram.webp" alt="ram" width="100%" height="100%">
                </div>
            </div>
            </div>
            <div class="box">
            <i class="fa fa-quote-left quote"></i>
            <p>I am happy to register my company on <a href=""> GetYourVendor.com</a>, they have hundreds of buyers and vendors from all over India. GYV’s alert feature for the new RFQ gave me my bulk order from the buyer within month.I strongly recommend GetYourVendor.com to my fellow suppliers.</p>
            <div class="content">
                <div class="info">
                <div class="name">Mr. Arvind Shete</div>
                <div class="job">Owner|MNS Engineers</div>
                <div class="stars">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                </div>
                </div>
                <div class="image">
                <img src="assets/images/media/home/review/arvind.webp" alt="arvid" width="100%" height="100%">
                </div>
            </div>
            </div>
            <div class="box">
            <i class="fa fa-quote-left  quote"></i>
            <p>I am so glad that I have registered on <a href="">GetYourVendor.com</a>  within no time Buyer found me on the platform and I got new order. This platform is a real boost for Indian vendors to reach out to specific buyers.</p>
            <div class="content">
                <div class="info">
                <div class="name">Mr.Bhushan Pagar </div>
                <div class="job">Owner|Jet Engineering</div>
                <div class="stars">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                </div>
                </div>
                <div class="image">
                <img src="assets/images/media/home/review/bhushan.webp" alt="bhushan" width="100%" height="100%">
                </div>
            </div>
            </div>
        </div>
        <div class="container " style="padding-top: 3%;">
            <p style="text-align: right; text-transform: uppercase;"><a href="/testimonials"><b>View More Testimonials <i class="fa fa-hand-o-right fa-lg" style="color: orange;"></i></b></a></p>
            
        </div>

    </section>
    <section class="sptb innerpage-margin faq" style="background-color: #fcb30b;">
        <div class="container">
        <h2 style="margin-bottom:2%"> Frequently Asked Questions?</h2>
            <div aria-multiselectable="true" class="accordion1" id="accordion" role="tablist">
                 <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapseTwo">
                        1. Who can become Vendor? OR Supplier?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapseTwo" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;" >
                            Any company capable of custom manufacturing and registered under respective countries govt authorized Industry Laws.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapseThree">
                        2. Who can become a Buyer?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapseThree" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Any company interested in outsourcing manufacturing activities. 
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapseFour">
                        3. What are the charges for this  platform?
                    </div>
                    <div  class="collapse" data-parent="#accordion" id="collapseFour" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Starting price for subscription package approximately 60 INR per day.
                            For more information about our multiple package details <a href="contactus" target="_blank">Contact Us</a> 
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapseFive">
                        4. Can I upgrade from one package to another package?
                    </div>
                    <div  class="collapse" data-parent="#accordion" id="collapseFive" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Yes you can upgrade your current plan by clicking UPGRADE button from your dashboard.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapseSix">
                        5. How much difference I need to pay for upgrading my vendor package
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapseSix" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Upgrade process will automatically calculates the difference based on your current plan start date. 
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse8">
                        6. How many registrations are allowed under single company name?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse8" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our premium packages will allow multiple registration under one company name. You can add more users under one package by paying nominal user fees.
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse10">
                       7. How to contact buyer for further communication once new RFQ is found. 
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse10" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            You can contact buyer based on the contact details provided in RFQ , by phone or email. 
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse11">
                        8. Do I need to contact via GYV for further communication to buyer or vendor?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse11" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            You can directly reach buyer or vendor.
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse12">
                        9. What If I have not received the receipt for the payment made.
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse12" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            You can access your invoice by logging in your account and click on settings>payment. 
                        </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse12">
                        10. Can I get my money refunded if I do not get RFQ's?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse12" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                           Please read our <a href="terms" target="_blank">Terms</a>  for more information. 
                        </div>
                    </div>
                </div>
                
            </div><!-- accordion -->
        </div>
    </section>
    <login-home-footer></login-home-footer>