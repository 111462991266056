
		<!--Footer Section-->
		<section class="sptb">
			<footer class="bg-dark text-white">
				<div class="footer-main">
					<div class="container">
						<div class="row">
							<div class="col-md-3 col-sm-3">
								<a class="footerimg-logo" href="#" ><img src="assets/images/brand/logo-dark.webp" width="100%" height="100%"  class="header-brand-footerimg" alt="GetYourVendor" loading="lazy"></a>
								
								<p style="color: white;">Get Your Vendor is a revolutionary end-to-end solution to bring registered Vendors & Buyers together and to help them Connect and Collaborate conveniently! ... <a routerLink="/aboutus" style="color:#eb751c">read more</a> </p>
									  
							</div>
							
							
							
							<div class="col-md-2 col-sm-2">
								<div>
								 <h6>Company</h6>
								 <hr class="text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto">
								</div>
								<div>
								<ul class="list-unstyled mb-0">
									
									 <div><a routerLink="/aboutus">About Us</a></div>
									<div><a routerLink="/blog">Blogs</a></div>
									<!-- <li><a routerLink="/pricing/Vendor/global">Pricing</a></li> -->
									<div><a routerLink="/faq">FAQ</a></div>
									<div><a routerLink="/career">Careers</a></div>
									<div><a routerLink="/terms">Terms of Use</a></div>
									<div><a routerLink="/privacy-policy">Privacy Policy</a></div>
								</ul>
								</div>
							</div>

							<div class="col-md-3 col-sm-3">
								<!-- <h6>Contact</h6>
								<hr class="text-primary accent-1 mb-1 mt-0 d-inline-block mx-auto">
								<ul class="footer-contact list-unstyled mb-0 contact-footer">
									<li>
										<i class="fa fa-envelope mr-0 text-white contact-icon" ></i><a href="mailto:contact@getyourvendor.com"> contact@getyourvendor.com</a></li>
									<li>
										<i class="fa fa-phone mr-0 text-white contact-icon"></i>91-9049000765 (Sales and Support)<br>
										91-9049000715 (For Corporate Sales)
									</li>
									<li>
										<i class="fa fa-home mr-0 text-white contact-icon"></i>Indisourcing Solutions Pvt Ltd,<br>501,Siddharth Towers
											<br>Kothrud ,Pune-411038, 
											 <br>Maharashtra, India 
									</li>
									
									
                                </ul>
                                

                                 -->
								 	<div>
										<h6>Contact</h6>
										<hr class="text-primary accent-2 mb-2 mt-0 d-inline-block mx-auto">	

									</div>
									<div>
										<li>
											<i class="fa fa-envelope text-white contact-icon "></i><a href="mailto:contact@getyourvendor.com"> contact@getyourvendor.com</a>
										</li>
									</div>
									<div>
										<li>
											<i class="fa fa-phone mr-1 text-white contact-icon"></i>91-9049000765 (Sales and Support)<br>
											91-9049000715 (For Corporate Sales)
										</li>
									</div>
									<div>
										<li>
											<i class="fa fa-home mr-1 text-white contact-icon"></i>Indisourcing Solutions Pvt Ltd,<br>501,Siddharth Towers
												<br>Kothrud ,Pune-411038, 
												 <br>Maharashtra, India 
										</li>

									</div>

								 
							</div>
							<div class="col-lg-3 col-md-3 col-sm-3">
								 <div>
								   <h6>Follow us on Social Media</h6> 
								   <hr class="deep-purple  text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto">
								</div>
								<div class="media">
								   <div class="fa"><a class="mx-1" href="https://www.linkedin.com/company/get-your-vendor" target="_blank" title="Follow us on LinkedIn">
										<i class="fa fa-linkedin fa-1" ></i>
									  </a> </div>
			
									  
									<div class="fa"><a class="mx-1" href="https://www.facebook.com/gettyourvendor" target="_blank" title="Follow us on Facebook">
										<i class="fa fa-facebook blue fa-1" ></i>
									  </a> </div>
									
								
									<div class="fa"><a class="mx-1" href="https://www.instagram.com/getyourvendor/" target="_blank" title="Follow us on Instagram">
										<i class="fa fa-instagram blue" ></i>
									  </a></div>
			
									  
									  <div class="fa"><a class="mx-1" href="https://twitter.com/GetYourVendor" target="_blank" title="Follow us on Twitter">
										<i class="fa fa-twitter blue" ></i>
									  </a></div>
			
									  <div class="fa"><a class="mx-1" href="https://in.pinterest.com/getyourvendor/ " target="_blank" title="Follow us on Pinterest">
										<i class="fa fa-pinterest blue" ></i>
									  </a></div>
									  
									  
									  <div class="fa"><a class="mx-1" href="https://www.youtube.com/channel/UCqLa--YJVYZtbpiiMUhi4JQ" target="_blank" title="Subscribe our YouTube Channel">
										<i class="fa fa-youtube blue" ></i>
									  </a></div>
							  
								</div>

							</div>
						</div>
					</div>
				</div>
				<div class="bg-dark text-white-50 p-0">
					<div class="container">
						<div class="row d-flex">
							<div class="col-lg-12 col-sm-12 mt-3 mb-3 text-center" style="color: white;">
								Copyright © 2023 INDISOURCING SOLUTIONS PVT LTD. All Rights Reserved.
							</div>
						</div>
					</div>
				</div>
			</footer>
		</section>
<!-- Back to top -->
<a href="#top" id="back-to-top"><i class="fa fa-long-arrow-up"></i></a>

<div id="mybutton1">
	<!-- <button class="feedback">Request Demo</button> -->
	<button (click)="openDialog()" class="btn btn-info" >Schedule a Call Back!</button>	
  </div>


