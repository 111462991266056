import { Component, OnInit } from '@angular/core';

import { GlobalComponent } from 'src/app/global/global.component';
import { SeoService } from 'src/app/service/seo.service';

@Component({
  selector: 'login-behinds',
  templateUrl: './behinds.component.html',
  styleUrls: ['./behinds.component.scss']
})
export class BehindsComponent implements OnInit {

  hostName = GlobalComponent.hostName;
  
  constructor(private SEOService:SeoService) { }

  ngOnInit() {
    this.SEOService.updateCanonicalUrl(this.hostName + 'blog/The-Manufacturing-Industry-Behind-the-Scenes');
    
  }

}
