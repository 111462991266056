<div class="banner-1 cover-image bg-background1" data-image-src="assets/images/banners/banner4.jpg">
    <login-home-navigation></login-home-navigation>


   
    <p>&nbsp;</p>
  
</div>
    <section style="background-color: white;">
        <div class="sptb" style="margin-top:80px">
            <div class="header-text mb-0">
                <div class="container">
                    <div class="text-center">
                        <h1>Password Reset</h1>
                       
                    </div>
                </div>
            </div>
        </div>
    </section>
  


<!--Login-Section-->
<section class="sptb" style="background-color: white;">
    <div class="container customerpage">
        <div class="row">
            <div class="single-page">
                <div class="col-lg-5 col-xl-4 col-md-6 d-block mx-auto">
                    <div class="wrapper wrapper2">
                     
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="card-body" >
                            <div class="mail">
                                <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                <label>Enter Registered Email</label>
                                <div style="text-align: left;" *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Valid Email Id is required</div>
                                    <div *ngIf="f.email.errors.pattern">Enter Valid Email Id</div>
                                </div>
                            </div>
                          


                            <div class="submit">
                                <button [disabled]="loading" class="btn btn-primary btn-block">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                    Send Reset Password Link
                                </button>
                            </div>
                            
                        </form>



                      
            
            
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-home-footer></app-home-footer>