import { Component, OnInit } from '@angular/core';

import { SeoService } from '../service/seo.service';
import { GlobalComponent } from '../global/global.component';

@Component({
  selector: 'login-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {
  
 hostName = GlobalComponent.hostName;


  constructor(private SEOService:SeoService) { }

  ngOnInit(){

  this.SEOService.updateCanonicalUrl(this.hostName + '/aboutus');

  


  }

}
