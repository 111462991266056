import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { filter } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { isBs3 } from 'ngx-bootstrap/utils';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { User } from 'src/app/model/user.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';

declare let $: any;
@Component({
  selector: 'login-home-navigation',
  templateUrl: './home-navigation.component.html',
  styleUrls: ['./home-navigation.component.scss']
})

export class HomeNavigationComponent implements OnInit {
	searchflags: any;
  search_level: any;
  processes: any = [];
  machinetype: any;
  categories: any = [];
  searchlist: any = [];
  parameters: any = [];
  suggestion: any= [];
  selected: string;
  states:any = [];
  
  isBs3 = isBs3();
	//authenticationService: any;

  myuser: any;
  currentUser: User;
  usertype: any;
  currentuser: any;

 // title: any;

  selectEvent(item) {
    // do something with selected item
  }

  constructor( private authenticationService: AuthenticationService,
    private meta: Meta,
    private titleService: Title, public dialog: MatDialog,
	private router: Router) {
    
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.myuser = this.currentUser; 
     
   }

 
 
   onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  

  searchclick(match){
    //console.log(match);
  }



//====================search function list====================== 
searchlevel(){

    if(this.searchflags['rfq']=='Y' ){
    this.search_level = "R";
    }
    if(this.searchflags['category']=='Y' ){
    this.search_level = 1;
    }
    if(this.searchflags['category']=='Y' && this.searchflags['process']=='Y'){
    this.search_level = 2;
    }
    if(this.searchflags['category']=='Y' && this.searchflags['process']=='Y' && this.searchflags['machine_type']=='Y'){
    this.search_level = 3;
    }
    if(this.searchflags['category']=='Y' && this.searchflags['process']=='Y' && this.searchflags['machine_type']=='Y' && this.searchflags['parameter']=='Y'){
    this.search_level = 4;
    }

    // console.log(this.search_level);
    
    
}

setDocTitle(title: string, meta_tag: string) {
     
  localStorage.setItem('new_title', title);
  localStorage.setItem('new_meta', meta_tag);
 // this.titleService.setTitle(title);
  
}

setflags(flagname: any){
  this.searchflags[flagname] = 'Y'; 
}
searchrfq(){
  return this.processes;
}

searchprocess(name){
  return this.processes.filter(labels => labels.name.toLowerCase() == name.toLowerCase());
}
searchmachinetype(name){
  return this.machinetype.filter(labels => labels.name.toLowerCase() == name.toLowerCase());
}
filterprocess(){
  let plist:any = this.processes.filter(labels => labels.category_id == this.categories.filter(labels => labels.name.toLowerCase() == this.searchlist[0].toLowerCase())[0].id);

  return plist;
}
filtermachinetypes(){
  let plist:any = this.machinetype;
  return plist;
}

filterparameters(){
  let plist:any = this.parameters;
  return plist;
}

refreshflag(){
  this.searchflags =  {"category": 'N', "process": 'N', "machine_type": "N", "parameter": "N", "rfq": "N"};
 }




flagsfind(search: any){
  // refresh flags and list
  if(search==""){
    this.refreshflag();
    this.searchlist = [];
  }

// when category is avialable 
  if(this.searchflags['category']=='Y' && this.searchflags['process']=='N'){
   // console.log(this.searchlist[0]);
//    console.log(search.toLowerCase().replace(this.searchlist[0].toLowerCase()+' ',''));
    if(this.searchprocess(search.toLowerCase().replace(this.searchlist[0].toLowerCase()+' ,','')).length!=0){
      let temp1 = this.searchprocess(search.toLowerCase().replace(this.searchlist[0].toLowerCase()+' ,',''))[0].name;
      //console.log(temp1);
      this.searchlist[1] = temp1;
      this.setflags('process');
      this.searchflags['process']='Y';
      
    }
  }


  if(this.searchflags['category']=='Y' && this.searchflags['process']=='Y'){
    // console.log(this.searchlist[0]);

    if(this.searchmachinetype(search.toLowerCase().replace(this.searchlist[0].toLowerCase()+' ,'+this.searchlist[1].toLowerCase()+' ,','')).length!=0){
      let temp1 = this.searchmachinetype(search.toLowerCase().replace(this.searchlist[0].toLowerCase()+' ,'+this.searchlist[1].toLowerCase()+' ,',''))[0].name;
      // console.log(temp1);
      this.searchlist[2] = temp1;
      this.setflags('machine_type');
      this.searchflags['machine_type']='Y';
      
    }
  }

  
//for rfq check 

  if(search.toLowerCase().indexOf("rfq".toLowerCase()) > -1 || search.includes("rfq".toLowerCase())){
  //console.log("parameter find");
 
  this.setflags('rfq');

    

  }

  if(this.searchflags['rfq']=='Y'){
    this.searchlist[5] = "RFQ,";
  }



  
this.categories.forEach(element => {
  if(element.name.toLowerCase()==search.toLowerCase()){
    // console.log("category found");
    this.setflags('category');
    this.searchlist[0] = element.name;
  }
});

this.processes.forEach(element => {
  if(element.name.toLowerCase()==search.toLowerCase()){
    //console.log("process find");
    this.searchlist[1] = element.name;
    if(this.searchlist[0].toLowerCase()==this.searchlist[1].toLowerCase()){
      this.searchflags['process'] = 'N'; 
    }else{
      this.setflags('process');
      
    }
    
  }
});

this.machinetype.forEach(element => {
  if(element.name.toLowerCase().indexOf(search.toLowerCase()) > -1){
    //console.log("parameter find");
    this.setflags('machine_type');
    this.searchlist[2] = element.name;
  }
});

this.parameters.forEach(element => {
  if(element.sp_label_name.toLowerCase()==search.toLowerCase()){
    //console.log("parameter find");
    this.setflags('parameter');
    this.searchlist[4] = element.sp_label_name;
  }
});



  
}


generatesearchlist(){
  // this.usertype =  localStorage.getItem('usertype');

  if(this.search_level==1){
      let templist = this.filterprocess();
      let search_suggesion:any = [];
      let list = this.searchlist;
      let i = 0;
      templist.forEach(element => {
        search_suggesion[i] = list[0]+' ,'+element.name;
        i = i + 1;
      });
      // console.log(search_suggesion);
      this.suggestion = search_suggesion;
     // console.log(search_suggesion);
  }


  if(this.search_level==2){
    let templist = this.filtermachinetypes();
    let search_suggesion:any = [];
    let list = this.searchlist;
    let i = 0;
    templist.forEach(element => {
      search_suggesion[i] = list[0]+' ,'+list[1]+' ,'+element.name;
      i = i + 1;
    });

    this.suggestion = search_suggesion;
   // console.log(search_suggesion);
  }

  if(this.search_level==3){
    let templist = this.filterparameters();
    let search_suggesion:any = [];
    let list = this.searchlist;
    let i = 0;
    templist.forEach(element => {
      search_suggesion[i] = list[0]+' ,'+list[1]+' ,'+list[2]+' ,'+element.sp_label_name;
      i = i + 1;
    });
    this.suggestion = search_suggesion;
  }

  if(this.search_level=='R'){
    let templist = this.processes;
    let search_suggesion:any = [];
    let list = this.searchlist;
    let i = 0;
    templist.forEach(element => {
      search_suggesion[i] = list[5]+' '+element.name;
      i = i + 1;
    });
    this.suggestion = search_suggesion;
  }


}


//====================search function list end====================== 








  searchfunction(ev){
// console.log(ev.target.value);

let i = 0;
let searchcategory:any = [];

this.flagsfind(ev.target.value);
//console.log(this.searchflags);
//console.log(this.searchlist);
this.searchlevel();
// console.log(this.search_level)
this.generatesearchlist();

  }

  vendorlist1(){

    this.router.navigate(['/dashboard']);

  }

  
  mysearch(ev){
   // console.log(ev.target.value);
   
    
      }


      


  onFocused(e){
    // do something when input is focused
  }

  ngOnInit(): void {
	// this.router.events
	// .pipe(filter(event => event instanceof NavigationEnd))  
	// .subscribe((event: NavigationEnd) => {
	//   // code goes here...
	//   console.log("changes in route");
	//   window.location.reload();
	// });
  this.usertype = "Vendor";
// console.log(this.myuser);
if(this.myuser != null){
  this.authenticationService.currentuser(this.myuser.success.user.userid)
  .pipe(first())
  .subscribe(
      data => {

      this.currentuser = data.message;
      this.usertype = this.currentuser.user_type;
      })
}  
  
  // console.log(localStorage.getItem('new_meta'));
  // console.log(localStorage.getItem('new_title'));
  // if(localStorage.getItem('new_title') == null){
  //   this.titleService.setTitle('GetYourVendor: Best B2B custom manufacturing facility search portal');
  //   this.meta.addTag({
  //     name: 'Getyourvendor',
  //     content: 'Best B2B custom manufacturing facility search portal'
  //   });
  //   this.meta.updateTag(
  //     {
  //       name: 'Best B2B custom manufacturing facility search portal',
  //       content: 'Get Your Vendor the best b2b manufacturing marketplace connects vendors and buyers towards facilitating manufacturing multiple categories, which would streamline into global sourcing and custom manufacturing.'
  //     });
  // }else{
	// this.titleService.setTitle(localStorage.getItem('new_title'));
  //   this.meta.addTag({
  //     name: 'description',
  //       content: localStorage.getItem('new_meta')
  //   });
    
  //   if(this.titleService.getTitle() == 'Custom manufacturing- The USP of distinctive brands- Get Your Vendor'){
  //     this.meta.addTag({
  //       name: 'keywords',
  //       content: 'custom manufacturing'
  //     });
  //   }else{
  //   if(this.titleService.getTitle() == 'Procurement Management System - Get Your Vendor'){
  //       this.meta.addTag({
  //         name: 'keywords',
  //         content: 'procurement management system'
  //       });
  //   }else{
  //   if(this.titleService.getTitle() == 'Vendor Mangement System- Get Your Vendor'){
  //         this.meta.addTag({
  //           name: 'keywords',
  //           content: 'vendor management system,vendor development system'
  //         });
  //   }else{
  //     this.meta.addTag({
  //       name: 'keywords',
  //       content: 'procurement management system,custom manufacturing,vendor management system,vendor development system'
  //     });
  //   }
  //     }
  //   }
  //  /* this.meta.updateTag(
  //     {
  //       name: 'description',
  //       content: localStorage.getItem('new_meta')
  //     });*/
  //   }

	$("body").removeClass('active');
	
    // let scrollToTop = window.setInterval(() => {
    //   let pos = window.pageYOffset
    //   if(pos > 0){
    //       window.scrollTo(0, pos - 20);

    //   }else{
    //     window.clearInterval(scrollToTop);
    //   }
    // }, 16); 



		$('body').wrapInner('<div class="horizontalMenucontainer" />');
		$('<div class="horizontal-overlapbg"></div>').prependTo('.horizontalMenu');
		$('#horizontal-navtoggle').on("click", function(e) {
			// $('body').toggleClass('active');
		});
		$('.horizontal-overlapbg').on("click", function(e) {
			$("body").removeClass('active');
		});
		$('.horizontalMenu > .horizontalMenu-list > li').has('.sub-menu').prepend('<span class="horizontalMenu-click"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span>');
		$('.horizontalMenu > .horizontalMenu-list > li').has('.horizontal-megamenu').prepend('<span class="horizontalMenu-click"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span>');
		$('.horizontalMenu-click').on("click", function(e) {
			$(this).toggleClass('horizontal-activearrow').parent().siblings().children().removeClass('horizontal-activearrow');
			$(".horizontalMenu > .horizontalMenu-list > li > .sub-menu, .horizontal-megamenu").not($(this).siblings('.horizontalMenu > .horizontalMenu-list > li > .sub-menu, .horizontal-megamenu')).slideUp('slow');
			$(this).siblings('.sub-menu').slideToggle('slow');
			$(this).siblings('.horizontal-megamenu').slideToggle('slow');
		});
		$('.horizontalMenu > .horizontalMenu-list > li > ul > li').has('.sub-menu').prepend('<span class="horizontalMenu-click02"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span>');
		$('.horizontalMenu > .horizontalMenu-list > li > ul > li > ul > li').has('.sub-menu').prepend('<span class="horizontalMenu-click02"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span>');
		$('.horizontalMenu-click02').on("click", function(e){
			$(this).children('.horizontalMenu-arrow').toggleClass('horizontalMenu-rotate');
			$(this).siblings('li > .sub-menu').slideToggle('slow');
    });
    
// ______________Active Class
$(".horizontalMenu-list li a").each(function(e) {
  var pageUrl = window.location.href.split(/[?#]/)[0];
  if (this.href == pageUrl) {
    $(this).addClass("active");
    $(this).parent().addClass("active"); // add active to li of the current link
    $(this).parent().parent().prev().addClass("active"); // add active class to an anchor
    $(this).parent().parent().prev().click(); // click the item to make it drop
  }
});

	/*	$(window).on('resize', function(e) {
			if ($(window).outerWidth() < 992) {
				$('.horizontalMenu').css('height', $(this).height() + "px");
				$('.horizontalMenucontainer').css('min-width', $(this).width() + "px");
			} else {
				$('.horizontalMenu').removeAttr("style");
				$('.horizontalMenucontainer').removeAttr("style");
				$('body').removeClass("active");
				$('.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu, .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu, .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu, .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu').removeAttr("style");
				$('.horizontalMenu-click').removeClass("horizontal-activearrow");
				$('.horizontalMenu-click02 > i').removeClass("horizontalMenu-rotate");
			}
		});
		$(window).trigger('resize');*/
		this.refreshflag();

		$('.js-example-basic-single').select2({
		  theme: "classic"
		});
	
		
	
		
		// this.authenticationService.getcategory()
		// .pipe(first())
		// .subscribe(
		// 	data => {
			  
		//  this.categories = data.message.category;
		//  this.processes = data.message.process;
		// this.machinetype = data.message.machinename;
		// this.parameters = data.message.sf_label_master;
	
		//   //console.log(this.categories);
		
			  
		// 	},
		// 	error => {
				
			   
		// 	});

  }

  happy(){
	$('body').toggleClass('active');
  }
  expandMenu(e) {
    e.target.parentElement.parentElement.classList.toggle("horizontal-activearrow")
  }

  openDialog(): void {
    this.dialog.open(GyvMartdlgComponent, {
      width: '500px',
    });

  
  }

  
}



@Component({
  selector: 'gvy-martdlgcomponent',
  templateUrl: 'gyv-martdlg.component.html',
   styleUrls: ['home-navigation.component.scss']
})
export class GyvMartdlgComponent implements OnInit {
  leadForm: UntypedFormGroup;
  submitted = false;

  constructor(public dialogRef: MatDialogRef<GyvMartdlgComponent>,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    public toastr: ToastrManager) {}

    ngOnInit() {
        this.leadForm = this.formBuilder.group({
            company_name: ['', Validators.required],
            contact_name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, Validators.minLength(10), Validators.pattern("^[0-9]*$")]],
        });
    }
  
    // convenience getter for easy access to form fields
    get f() { return this.leadForm.controls; }
  
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.leadForm.invalid) {
            return;
        }
  
        //console.log(this.f.email.value);
          this.authenticationService.create_gyvmartlead(this.f.company_name.value,this.f.contact_name.value,this.f.email.value, this.f.phone.value)
              .pipe(first())
              .subscribe(
                  data => {
                    console.log(data);
                    this.toastr.successToastr("Thank You!", 'Our Team will call you within 48 hours!');
                  },
                  error => {
                  });
    
        this.dialogRef.close();
         
    }
}