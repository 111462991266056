<div class="banner-1 cover-image bg-background1" data-image-src="assets/images/banners/banner4.jpg">
    <app-home-navigation></app-home-navigation>


    <!-- <section>
        <div class="bannerimg">
            <div class="header-text mb-0">
                <div class="container">
                    <div class="text-center text-white">
                        <h1>FAQ</h1>
                        <ol class="breadcrumb text-center">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            
                            <li class="breadcrumb-item active text-white" aria-current="page">FAQ</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!--/Section-->

    <p>&nbsp;</p>
  
	
    	<!--Section-->
		<section class="sptb" style="margin-top:100px;">
			<div class="container">
				<div class="row">
				
					<div class="col-xl-12 col-lg-12 col-md-12">
						<div class="row blog-grid">
						
							<div class="col-xl-4 col-lg-6 col-md-12">
								<div class="card">
									<div class="item7-card-img">
										<a routerLink="/faq"></a>
										<img src="assets/images/media/faq.webp" alt="FAQ" class="cover-image">
									</div>
									<div class="card-body">
										<h4 class="fs-20"><a routerLink="/faq" class="text-dark">Your Questions, Answered!</a></h4>
										
										<p class="fs-14 help-text">We have put together some commonly asked questions to give you more information about Get Your Vendor.</p>
										<a routerLink="/faq" class="btn btn-primary"><i class="fa fa-list"></i> View FAQ</a>
									</div>
								</div>
							</div>
							<div class="col-xl-4 col-lg-6 col-md-12">
								<div class="card">
									<div class="item7-card-img">
										<a routerLink="/blog"></a>
										<img src="assets/images/media/blog.webp" alt="blog" class="cover-image">
									</div>
									<div class="card-body">
										<h4 class="fs-20"><a routerLink="/blog" class="text-dark">Experts Speak</a></h4>
										
										<p class="fs-14 help-text">Read through these insightful blogs & articles to brush up on your knowledge of the Indian Manufacturing Industry. </p>
										<a routerLink="/blog" class="btn btn-primary"><i class="fa fa-pencil"></i> View Blog</a>
										<!-- <a href="https://blog.getyourvendor.com" target="_blank" class="btn btn-primary"><i class="fa fa-pencil"></i> View Blog</a> -->
									</div>
								</div>
							</div>
							<div class="col-xl-4 col-lg-6 col-md-12">
								<div class="card">
									<div class="item7-card-img">
										<a routerLink="/contactus"></a>
										<img src="assets/images/media/email-support.jpg" alt="email-support" class="cover-image">
									</div>
									<div class="card-body">
										<h4 class="fs-20"><a routerLink="/contactus" class="text-dark">Reach Out to Us</a></h4>
										
										<p class="fs-14 help-text"  id="faq">We like to help, let us know how! <br>&nbsp; <br>&nbsp; </p>
										<a routerLink="/contactus" class="btn  btn-primary"><i class="fa fa-envelope"></i> Email us</a>
									</div>
								</div>
							</div>
							
						</div>
						<!-- <div>
							<a routerLink="/updatedpricing"> <img src="./assets/images/ads/monsoon.jpeg" alt="custom manufacturing" class="offerimage">  </a>
							 
						</div> -->
					</div>
				</div>
			
			
			</div>
			
		</section>
		<!--/Section-->
		
</div>



  
<!-- /Conatct -->


<app-home-footer></app-home-footer>
