<div class="banner-1 cover-image bg-background1" data-image-src="assets/images/banners/banner4.jpg">
    <login-home-navigation></login-home-navigation>
 
</div>

<section class="sptb">
    <div class="container card">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <p><img src="assets/images/media/strategicpartner.webp" width="100%" height="auto"></p>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 card">
            <div class="card-body">
                <p>Get Your Vendor is a revolutionary end-to-end solution to bring registered Vendors & Buyers together and to help them conveniently Connect and Collaborate!
                    Sourcing is a crucial part of the Manufacturing Industry and identifying the right vendor can make or break a business. But the process is quite lengthy, tedious, and expensive. It requires a lot of man-hours, capital, skills, etc. Resources that can be better used to enhance your core business.
                    Our team has lived through and dealt with all such challenges vendor development can pose. It was the need to address these challenges, a desire to align the vendor development space with the 21st century, and to shift paradigms in the sourcing process that lead the team to create an industry-specific platform that would allow Vendors & Buyers from across the world to connect.
                    </p>
            </div>
            <div class="card-header">
                <h1> Who can Join this Program? </h1>
            </div>
            <div class="card-body">
                <p> India is playing major role in global custom manufacturing world.</p>
                <p>We would like to offer our strategic program to similar interest individual or organizations experts in any of the following areas. </p>
                <p><i class="fa fa-hand-o-right fa-lg" style="color: orange;"></i> Vendor Development<p>
                <p><i class="fa fa-hand-o-right fa-lg" style="color: orange;"></i> Sourcing Consultants </p>
                <p><i class="fa fa-hand-o-right fa-lg" style="color: orange;"></i> Purchase Experts </p>
                <p><i class="fa fa-hand-o-right fa-lg" style="color: orange;"></i> Procurement  </p>
                <p><i class="fa fa-hand-o-right fa-lg" style="color: orange;"></i> Distribution Organizations </p>
                
                    
            </div>

            <div class="card-body">
                <h2>How does this program works? </h2>

                <ul class="fa-ul" style="--fa-li-width: 2em;">
                    <li><span class="fa-li"><i class="fa fa-solid fa-check-square"></i></span><a href="login/signup1" style="color: blue;">SignUp</a> for Free as a  Buyer using following link </li>
                    <li><span class="fa-li"><i class="fa fa-solid fa-check-square"></i></span>Complete basic contact information.</li>
                    <li><span class="fa-li"><i class="fa fa-solid fa-check-square"></i></span>Our strategic partner program experts will call you to provide free orientation of the  program. </li>
                  </ul>
            </div>
        </div>

       

    </div>
</section>



<login-home-footer></login-home-footer>
