<div class="banner-1 cover-image bg-background1" data-image-src="assets/images/banners/banner4.jpg">
    <login-home-navigation></login-home-navigation>
           
          
</div>

<section class="sptb innerpage-margin" style="background-color: white;">
    <div class="container">

        <div class="row">
        
            <div class="col-md-12 col-sm-12" style="margin-top: 5%;">
                <!--Itemsd lists-->
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h1 style="text-align: center;">Are you a Vendor/Supplier?</h1>
								<div class="item7-card-desc d-flex mb-5">
									<!-- <a href="#" class="text-muted"><i class="fa fa-calendar-o mr-2"></i>Oct-18-2021</a> -->
									
									
								</div>
								<div>
								<img src="assets/images/media/custom_manufacturing.webp" alt="img" width="420">
								<p>
                                    GetYourVendor is a user-friendly platform for Indian and International manufacturing Vendors/Suppliers to connect with Indian and global manufacturing Buyers.</p>
								<h3><b>Who can publish requirements?</b>&nbsp; <a href="login/signup1" ><button class="fbutton">SignUp Now</button></a></h3>
                                <p>Anyone ready to manufacture, and has a manufacturing facility.
                                    Companies that would like to utilize their extra manufacturing capacity or would like to diversify from one manufacturing category to another. </p>
								<h3><b>Why GetYourVendor platform?</b></h3>
                                <p>The platform provides a user-friendly interface for suppliers to view their category/process <a href="/login"> RFQ</a>, as a supplier you have the freedom to choose the RFQ for your business. You can directly contact the buyer or send a quotation through the portal without any middle-man. You can view the drawings and target price in one view. You can track your quotation status and RFQ status. You can upload digital NDA (Non-Disclosure Agreement) through the platform to avoid the hassle of sending through the physical post. The platform allows you to list your complete technical capability through our domain-specific supplier assessment form. You can reduce your sales and marketing costs by registering your company on the GetYourVendor platform.</p>		
                                <h3><b>What can you expect as a Vendor/Supplier ?</b></h3>
                                <p>Buyers can directly contact you through the portal based on the RFQs. Get quick intimation about new <a href="/login">RFQ's</a> published on the platform, get direct  Buyer names and contact details, can showcase your machines and other facilities through the platform. Get the opportunity to connect with big customers and increase your profit margin. </p>    
                                <h3><b>What does GetYourVendor platform offer?</b></h3>  

                                    <p><li>Publish Company Profile </li></p>
                                    <p><li>Get RFQ directly to Inbox</li> </p> 
                                    <p><li>Digital NDA </li></p>
                                    <p><li>Virtual Vendor Assessment</li></p> 
                                    <p><li>Quotation Tracking</li></p>
                                    
                                 <h3><b>Who are We?</b></h3>
                                 <p>We are an Indian company founded in 2020 by industry experts having International and domestic exposure to manufacturing industries. 
                                    We are based in Pune, the hub of manufacturing, and expanding our service to the globe through the global platform. 
                                    We have genuine suppliers from all over India and expanding our reach to international suppliers. </p>   
                                 <h3><b>Why it is necessary to be on the Internet or a virtual platform?</b></h3>
                                 <p>In recent times we have seen all manufacturing sectors go through difficult times due to COVID. During these times many companies had to shut down their business due to various reasons lack of manpower, lack of work, lack of sustaining funds, and lack of vision. But in many cases, companies had diversified their businesses and were aware of the Internet and its advantages. They kept going during the difficult time due to their futuristic vision to be on the Internet. 
                                    As India is growing in all sectors and making an impact on the world, govt are making sure that our Indian companies sustain as well as deliver the goods to the world and become financially independent, they have also taken steps to push all companies on the virtual world. We are in sync with the future to bring Indian companies to be on the Internet and showcase their technical ability to deliver quality goods. </p>
                                    <p>Publish your company profile Now <a href="login/signup1" ><button class="fbutton">SignUp Now</button></a></p>
                                </div>
							</div>
						</div>
                    </div>
                   
                </div>
                <div class="center-block text-center">
                </div>
                <!--/Itemsd lists-->
            </div>
            <!--Right Side Content-->
         
            <!--/Right Side Content-->
        </div>
    </div>
</section><!--/Section-->

<login-home-footer></login-home-footer>