<div class="banner-1 cover-image bg-background1" data-image-src="assets/images/banners/banner4.jpg">
    <login-home-navigation></login-home-navigation>


</div>
	<!--Section-->
    <section class="sptb innerpage-margin faq" style="background-color: #fcb30b;">
        <div class="container">
        <h2 style="margin-bottom:2%"> Frequently Asked Questions?</h2>
            <div aria-multiselectable="true" class="accordion1" id="accordion" role="tablist">
                 <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapseTwo">
                        1. Who can become Vendor? OR Supplier?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapseTwo" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;" >
                            Any company capable of custom manufacturing and registered under respective countries govt authorized Industry Laws.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapseThree">
                        2. Who can become a Buyer?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapseThree" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Any company interested in outsourcing manufacturing activities. 
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapseFour">
                        3. What are the charges for this  platform?
                    </div>
                    <div  class="collapse" data-parent="#accordion" id="collapseFour" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Starting price for subscription package approximately 60 INR per day.
                            For more information about our multiple package details <a href="contactus" target="_blank">Contact Us</a> 
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapseFive">
                        4. Can I upgrade from one package to another package?
                    </div>
                    <div  class="collapse" data-parent="#accordion" id="collapseFive" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Yes you can upgrade your current plan by clicking UPGRADE button from your dashboard.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapseSix">
                        5. How much difference I need to pay for upgrading my vendor package
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapseSix" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Upgrade process will automatically calculates the difference based on your current plan start date. 
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse8">
                        6. How many registrations are allowed under single company name?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse8" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our premium packages will allow multiple registration under one company name. You can add more users under one package by paying nominal user fees.
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse10">
                       7. How to contact buyer for further communication once new RFQ is found. 
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse10" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            You can contact buyer based on the contact details provided in RFQ , by phone or email. 
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse11">
                        8. Do I need to contact via GYV for further communication to buyer or vendor?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse11" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            You can directly reach buyer or vendor.
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse12">
                        9. What If I have not received the receipt for the payment made.
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse12" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            You can access your invoice by logging in your account and click on settings>payment. 
                        </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse12">
                        10. Can I get my money refunded if I do not get RFQ's?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse12" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                           Please read our <a href="terms" target="_blank">Terms</a>  for more information. 
                        </div>
                    </div>
                </div>
                
            </div><!-- accordion -->
        </div>
    </section>
    <!--/Section-->
  
<!-- /Conatct -->


<login-home-footer></login-home-footer>
