import { Component, OnInit } from '@angular/core';
import { GlobalComponent } from 'src/app/global/global.component';
import { SeoService } from 'src/app/service/seo.service';

@Component({
  selector: 'login-gyvb',
  templateUrl: './gyvb.component.html',
  styleUrls: ['./gyvb.component.scss']
})
export class GyvbComponent implements OnInit {

  hostName = GlobalComponent.hostName;

  constructor(private SEOService:SeoService) { }

  ngOnInit(){

  this.SEOService.updateCanonicalUrl(this.hostName + '/Procurement-Management-System');


  }

}
