import { DOCUMENT } from '@angular/common';
import { Component, enableProdMode, HostListener, OnInit, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GlobalComponent } from './global/global.component';
import { SeoService } from './service/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  title = 'getyourvendor';

  location: Location;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,@Inject(DOCUMENT) private dom,private SEOservice: SeoService) {
  }


  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e) {
  //   if (window.pageYOffset > 70) {
  //     let element = document.getElementById('t-navbar');
  //     if (element) {
  //       element.classList.add('h-sticky');
  //     }
  //   } else {
  //     let element = document.getElementById('t-navbar');
  //     if (element) {
  //       element.classList.remove('h-sticky');
  //     }
  //   }
  // }

  // onActivate(event){
  //   let scrollToTop = window.setInterval(() => {
  //     let pos = window.pageYOffset
  //     if(pos > 0){
  //         window.scrollTo(0, pos - 20);

  //     }else{
  //       window.clearInterval(scrollToTop);
  //     }
  //   }, 16) 
  // }

  ngOnInit() {
    if (environment.production) {
      enableProdMode();
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    //const hostName= GlobalComponent.hostName;
    
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {
      let seoData = data['seo'];
      this.SEOservice.updateTitle(seoData['title']);
      this.SEOservice.updateMetaTags(seoData['metaTags']);
      
    });
  
  }
  }


