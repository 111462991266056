import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../model/user.model';
//import { AnySrvRecord } from 'dns';


import { GlobalComponent } from '../global/global.component';


//const api = "https://app.getyourvendor.com/public";
//const api = "https://developmentapi.getyourvendor.com/public";

const api =  GlobalComponent.appsrcURL; 


export class Shortlist {
    id: number;
    name: string;
    address: string;
    firstName: string;
    lastName: string;
    token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  public Shortlist: Observable<Shortlist>;
  public BShortlist: BehaviorSubject<Shortlist>;
  public Wishlist: Observable<Shortlist>;
  public logintime: Date;
  public expiresOn: Date;

  constructor(private http: HttpClient) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));

      this.BShortlist = new BehaviorSubject<Shortlist>(JSON.parse(localStorage.getItem('shortlist')));
      this.currentUser = this.currentUserSubject.asObservable();
      this.Shortlist = this.BShortlist.asObservable();   
  }

  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  create_lead(company_name: string, email: string, phone: any) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/create_lead`, {company_name: company_name, email: email, phone: phone} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

create_gyvmartlead(company_name: string,contact_name:string, email: string, phone: any) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/create_martlead`, {company_name: company_name, contact_person:contact_name, email: email, phone: phone} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

  login(username: string, password: string) {
      return this.http.post<any>(`${api}/api/loginapi`, { email: username, password: password })
          .pipe(map(user => {
              // login successful if there's a jwt token in the response
            //  console.log(user);
            if (user.success && user.success.token) {
                  // store user details and jwt token in local storage to keep user logged in between page refreshes
                  localStorage.setItem('currentUser', JSON.stringify(user));
                  this.currentUserSubject.next(user);
                  this.logintime = new Date();       
                  localStorage.setItem('logintime', JSON.stringify(this.logintime));           
                  //this.expiresOn.setTime(this.logintime.getTime());
              }

              return user;
          }));
  }


  forgotpass(email: any) {
    return this.http.post<any>(`${api}/api/forgotlink`, { email: email })
        .pipe(map(reply => {
            // login successful if there's a jwt token in the response
            //console.log(reply);
            

            return reply;
        }));
}

subscribenow(email: any) {
    return this.http.post<any>(`${api}/api/subscribenow`, { email: email })
        .pipe(map(reply => {
            // login successful if there's a jwt token in the response
           // console.log(reply);
            

            return reply;
        }));
}



// ============ pagination ==========

gotothepage(url: any) {
    return this.http.post<any>(`${url}`, {})
        .pipe(map(reply => {
            // login successful if there's a jwt token in the response
           // console.log(reply);
            

            return reply;
        }));
}


// ============ pagination ==========

change_password(userid: any, password: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post<any>(`${api}/api/change_password`, {userid: userid, password: password} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

adduser(username: any, email: any, password: any, user_type: any, parent_userid: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post<any>(`${api}/api/adduser`, {username: username, email: email, password: password, user_type: user_type, parent_userid: parent_userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}


deleteuser(id: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post<any>(`${api}/api/deleteuser`, {id: id} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

deletenotifications(id: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post<any>(`${api}/api/deletenotifications`, {id: id} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

allparentuser(userid: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/allparentuser`, {userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}




// All Master API


allpackages(){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/allpackages`, {} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

allusers(){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/getallusers`, {} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

allnotifications(userid: any, offset:any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/allnotifications`, {userid: userid, offset: offset} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

new_notifications(userid: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/new_notifications`, {userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

update_notification_readstatus(id: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/update_notification_readstatus`, {id: id} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

update_quote_readstatus(quote_id: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/update_quote_readstatus`, {quote_id: quote_id} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

reject_quote(quote_id: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/reject_quote`, {quote_id: quote_id} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

buyer_reject_quote(quote_id: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/buyer_reject_quote`, {quote_id: quote_id} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

getcategory() {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/allmaster`, { } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

getallcategory() {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/getallcategory`, { } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

global_searchentry( usertype: any, searchkeyword: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/global_searchentry`, { usertype: usertype, searchkeyword: searchkeyword} ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}




sendmessage(name: any, email: any, subject: any, message: any, phone: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/sendmessage`, { name: name, email: email, subject: subject, message: message, phone: phone } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}



// All Data Need for RFQ API

getrfqform() {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/getrfqform`, { } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}


//to gather data while onselect
getrfq(rfqid:any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/getrfq`, {userid: userid, rfqid: rfqid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

get_rfq(rfqid:any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/get_rfq`, {rfqid: rfqid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

getrfqviewers_list(userid:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/getrfqviewers_list`,{userid: userid} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

getrfqviewers(rfqid:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/getrfqviewers`,{rfqid: rfqid} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

getLoginDate(userid:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/getLoginDate`,{userid: userid} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

getquotes(userid:any, myrole:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/getquotes`,{userid: userid, myrole: myrole} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

admin_getquotes(){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/admin_getquotes`,{} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

admin_quote_approved(quote_id: any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/admin_quote_approved`,{quote_id: quote_id} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

admin_quote_rejected(quote_id: any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/admin_quote_rejected`,{quote_id: quote_id} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

update_quote_status(quote_id:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/update_quote_status`,{quote_id: quote_id} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

buyer_update_quote_status(quote_id:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/buyer_update_quote_status`,{quote_id: quote_id} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

buyer_quotes_summary(userid:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/buyer_quotes_summary`,{userid: userid} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

receivedquotes(userid:any, rfqid:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/receivedquotes`,{userid: userid, rfqid: rfqid} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

review_buyers(){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/review_buyers`,{} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

buyer_emailverify(email:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/buyer_emailverify`,{email: email} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

reject_buyer(email:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/reject_buyer`,{email: email} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

rfqviewers(rfqid:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/rfqviewers`,{rfqid: rfqid} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

getprofileview(offset:any, userid:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/getprofileview`,{offset: offset, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

getshortlistedrfq(userid:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/getshortlistedrfq`,{userid: userid} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

getrfqquoteslist(userid:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/getrfqquoteslist`,{userid: userid} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

getactiverfq(){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/getactiverfq`,{} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}


getglobalactiverfq(offset:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/getglobalactiverfq`,{offset: offset} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

getlimitedrfq(userid:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/getlimitedrfq`,{userid: userid} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}

getglobalnonactiverfq(offset:any){
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/getglobalnonactiverfq`,{offset: offset} ,{
        headers: myheader
      })
        .pipe(map(rfqall => {
            

            return rfqall;
        }));

    
}






























//pages

vendorlisting(offset:any, params: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/vendorlisting`, {offset: offset, params: params, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

vendorlisting1(params: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/vendorlisting1`, {params: params, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

vendorlisting2(per_page:any, offset:any, searchkeyword: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/vendorlisting2`, {per_page: per_page, offset: offset, searchkeyword: searchkeyword, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {

            return vendorprofile;
        }));
}

countryfilter_vendorlisting(per_page:any, country:any, offset:any, searchkeyword: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/countryfilter_vendorlisting`, {per_page: per_page, country:country, offset: offset, searchkeyword: searchkeyword, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {

            return vendorprofile;
        }));
}

statefilter_vendorlisting(per_page:any, state:any, offset:any, searchkeyword: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/statefilter_vendorlisting`, {per_page: per_page, state:state, offset: offset, searchkeyword: searchkeyword, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {

            return vendorprofile;
        }));
}

cityfilter_vendorlisting(per_page:any, city:any, offset:any, searchkeyword: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/cityfilter_vendorlisting`, {per_page: per_page, city:city, offset: offset, searchkeyword: searchkeyword, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {

            return vendorprofile;
        }));
}

zipfilter_vendorlisting(per_page:any, zip:any, offset:any, searchkeyword: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/zipfilter_vendorlisting`, {per_page: per_page, zip:zip, offset: offset, searchkeyword: searchkeyword, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {

            return vendorprofile;
        }));
}



vendorlisting3(offset:any, searchkeyword: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/vendorlisting3`, {offset: offset, searchkeyword: searchkeyword, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

advance_vendorlisting(per_page:any, offset:any, searchkeyword: any ,searchtype: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/advance_vendorlisting`, {per_page: per_page, offset: offset, searchkeyword: searchkeyword, searchtype: searchtype, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

cityfilter_advance_vendorlisting(per_page:any, offset:any, city:any, searchkeyword: any ,searchtype: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/cityfilter_advance_vendorlisting`, {per_page: per_page, offset: offset, city: city, searchkeyword: searchkeyword, searchtype: searchtype, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

countryfilter_advance_vendorlisting(per_page:any, offset:any, country:any, searchkeyword: any ,searchtype: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/countryfilter_advance_vendorlisting`, {per_page: per_page, offset: offset, country: country, searchkeyword: searchkeyword, searchtype: searchtype, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

statefilter_advance_vendorlisting(per_page:any, offset:any, state:any, searchkeyword: any ,searchtype: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/statefilter_advance_vendorlisting`, {per_page: per_page, offset: offset, state: state, searchkeyword: searchkeyword, searchtype: searchtype, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

zipfilter_advance_vendorlisting(per_page:any, offset:any, zip:any, searchkeyword: any ,searchtype: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/zipfilter_advance_vendorlisting`, {per_page: per_page, offset: offset, zip: zip, searchkeyword: searchkeyword, searchtype: searchtype, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

countryfilter_vendorlistingbuyer(per_page:any, country:any, offset:any, params: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/countryfilter_vendorlistingbuyer`, {per_page: per_page, country:country, offset: offset, params: params, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

statefilter_vendorlistingbuyer(per_page:any, state:any, offset:any, params: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/statefilter_vendorlistingbuyer`, {per_page: per_page, state:state, offset: offset, params: params, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

cityfilter_vendorlistingbuyer(per_page:any, city:any, offset:any, params: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/cityfilter_vendorlistingbuyer`, {per_page: per_page, city:city, offset: offset, params: params, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

zipfilter_vendorlistingbuyer(per_page:any, zip:any, offset:any, params: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/zipfilter_vendorlistingbuyer`, {per_page: per_page, zip:zip, offset: offset, params: params, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

vendorlistingbuyer(per_page:any, offset:any, params: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/vendorlistingbuyer`, {per_page: per_page, offset: offset, params: params, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}



rfqform(process: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/rfqforms`, {process: process} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}


addtoshortlist(data: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/addtoshortlist`, {data: data} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            
            return vendorprofile;
        }));
}

isshortlistedvendor(userid: any, vendorid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/isshortlistedvendor`, {userid: userid, vendorid: vendorid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            
            return vendorprofile;
        }));
}


addtowishlist(data: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/addtowhishlist`, {data: data} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {

            localStorage.setItem('wishlist', JSON.stringify(vendorprofile.message));
            return vendorprofile;
        }));
}




addrfq(data: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/addrfq`, {data: data} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}


saverfq(data: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/saverfq`, {data: data} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}


deleterfq(myid: any, userid: any, process: any, rfqid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/deleterfq`, {id: myid, userid: userid,rfqid: rfqid, process: process} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

delete_rfq(rfqid: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/delete_rfq`, {rfqid: rfqid, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

delete_temp_rfq(rfq_form_id: any, rfqid: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/delete_temp_rfq`, {rfq_form_id: rfq_form_id, rfqid: rfqid, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

delete_bulk_rfq(rfq_form_id: any, rfqid: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/delete_bulk_rfq`, {rfq_form_id: rfq_form_id, rfqid: rfqid, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

close_rfq(rfqid: any, userid: any, vendor_id: any, buyer_company: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/close_rfq`, {rfqid: rfqid, userid: userid, vendor_id: vendor_id, buyer_company: buyer_company} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

admin_delete_rfq(rfqid: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/admin_delete_rfq`, {rfqid: rfqid, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

admin_close_rfq(rfqid: any, userid: any, vendor_id: any, buyer_company: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/admin_close_rfq`, {rfqid: rfqid, userid: userid, vendor_id: vendor_id, buyer_company: buyer_company} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

del_rfq(rfqid: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/del_rfq`, {rfqid: rfqid, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

cls_rfq(rfqid: any, userid: any, buyer_company: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/cls_rfq`, {rfqid: rfqid, userid: userid, buyer_company: buyer_company} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

updaterfq(certificateinfo) {  
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<any>(`${api}/api/updaterfq`, certificateinfo  ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));

}

get_allbuyers() {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/get_allbuyers`, {} ,{
        headers: myheader
      })
        .pipe(map(dashboard1 => {
            

            return dashboard1;
        }));
}

get_allvendors() {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/get_allvendors`, {} ,{
        headers: myheader
      })
        .pipe(map(dashboard1 => {
            

            return dashboard1;
        }));
}

map_vendors(buyer: any, vendors: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/map_vendors`, { buyer: buyer, vendors: vendors } ,{
        headers: myheader
      })
        .pipe(map(dashboard1 => {
            

            return dashboard1;
        }));
}

get_address(userid: any, userrole: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/get_address`, { userid: userid, userrole: userrole } ,{
        headers: myheader
      })
        .pipe(map(dashboard1 => {
            

            return dashboard1;
        }));
}

update_rfq(certificateinfo) {  
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<any>(`${api}/api/update_rfq`, certificateinfo  ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));

}

create_rfq(certificateinfo: any) {
    
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<any>(`${api}/api/create_rfq`, certificateinfo  ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}

bulk_rfq_import(certificateinfo: any) {
    
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<any>(`${api}/api/bulk_rfq_import`, certificateinfo  ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}

delete_search_history(search_id: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/delete_search_history`, {search_id: search_id, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

delete_searchhistory(search_id: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/delete_searchhistory`, {search_id: search_id, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

delete_bulk_searchhistory(search: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/delete_bulk_searchhistory`, {search: search, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

deleteshortlist(myid: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/deleteshortlist`, {id: myid, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

delete_shortlist(myid: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/delete_shortlist`, {id: myid, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

deleteshortlistedrfq(rfqid: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/deleteshortlistedrfq`, {rfqid: rfqid, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

deleteshortlisted_rfq(rfqid: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/deleteshortlisted_rfq`, {rfqid: rfqid, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

deletewishlist(myid: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/deletewishlist`, {id: myid, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

sendquotes(formData: any) {
    
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
   //const myheader = new HttpHeaders().set('Content-Type', 'application/json');    

    return this.http.post<any>(`${api}/api/sendquotes`, formData ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}

admin_quote_received(formData: any) {
    
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
   //const myheader = new HttpHeaders().set('Content-Type', 'application/json');    

    return this.http.post<any>(`${api}/api/admin_quote_received`, formData ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}


allrfq(userid: any, userrole: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/allrfq1`, {userid: userid, userrole: userrole} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

uploaded_rfqs(userid: any, userrole: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/uploaded_rfqs`, {userid: userid, userrole: userrole} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

uploaded_filtered_rfqs(pro: any, userid: any, userrole: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/uploaded_filtered_rfqs`, {pro: pro, userid: userid, userrole: userrole} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

editTempRFQ(rfqid: any, field: any, field_val: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/editTempRFQ`, {rfqid: rfqid, field: field, field_val: field_val} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

add_drawingTempRFQ(certificateinfo: any) {
    
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<any>(`${api}/api/add_drawingTempRFQ`, certificateinfo  ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}

review_rfq(userrole: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/review_rfq`, {userrole: userrole} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

search_history(userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/search_history`, {userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

getshortlisted_vendors(userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/getshortlisted_vendors`, {userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}


viewrfq(rfqid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/showrfq`, {rfqid: rfqid} ,{
        headers: myheader 
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

myVendors(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/myVendors`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

buyer_rfqid(rfqid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/buyer_rfqid`, {rfqid: rfqid} ,{
        headers: myheader 
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

floatrfq(rfqid: any, shortlist: any, type: any, city: any, userid) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/floatrfq`, {rfqid: rfqid, shortlist: shortlist, type: type, city: city, userid: userid} ,{
        headers: myheader 
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

float_temp_rfq(temp_rfqs_list: any, shortlist: any, type: any, city: any, userid) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/float_temp_rfq`, {temp_rfqs_list: temp_rfqs_list, shortlist: shortlist, type: type, city: city, userid: userid} ,{
        headers: myheader 
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

float_rfq(rfqid: any, userid: any, float_option: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/float_rfq`, {rfqid: rfqid, userid: userid, float_option: float_option} ,{
        headers: myheader 
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

reject_rfq(rfqid: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/reject_rfq`, {rfqid: rfqid, userid: userid} ,{
        headers: myheader 
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}


  Vendorcompanyprofile(companyprofile: string) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/vendorcompanyprofileupdate`, { companyprofile: companyprofile } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

Vendormachineupdate(companyprofile: string) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/Vendormachineupdate`, { companyprofile: companyprofile } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

Vendorcompanyaddress(companyprofile: string) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/Vendorcompanyaddress`, { companyprofile: companyprofile } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

Vendorcompanydetails(companyprofile: string) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/Vendorcompanydetails`, { companyprofile: companyprofile } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

vendorprofile(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/vendorprofileshow`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}

getSpecification(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/getSpecification`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            
            return vendorprofile;
        }));
}
getSoftwares(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/getSoftwares`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            
            return vendorprofile;
        }));
}
getCertificates(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/getCertificates`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            
            return vendorprofile;
        }));
}
getMachines(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/getMachines`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            
            return vendorprofile;
        }));
}


vendorprofileupdate(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/vendorprofileupdate`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}




buyerprofile(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/buyerprofileshow`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));
}


// This data api is for machine add and clone 
Vendormachinetable(companyprofile: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/addmachinetable`, { companyprofile: companyprofile } ,{
        headers: myheader
      })
        .pipe(map(vendormachine => {
            

            return vendormachine;
        }));
}



// This api is for machine update with machine row id
Vendormachinetableupdate(companyprofile: any, mid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/updatemachine`, { companyprofile: companyprofile, mid: mid } ,{
        headers: myheader
      })
        .pipe(map(vendormachine => {
            

            return vendormachine;
        }));
}



addspecification(companyprofile: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/addspecification`, { data: companyprofile } ,{
        headers: myheader
      })
        .pipe(map(data => {
            

            return data;
        }));
}



addequipment(companyprofile: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/addequipment`, { data: companyprofile } ,{
        headers: myheader
      })
        .pipe(map(data => {
            

            return data;
        }));
}



updatemachine(companyprofile: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/updatemachine`, { companyprofile: companyprofile } ,{
        headers: myheader
      })
        .pipe(map(vendormachine => {
            

            return vendormachine;
        }));
}


importmachine(companyprofile: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/importmachine`, { companyprofile: companyprofile } ,{
        headers: myheader
      })
        .pipe(map(vendormachine => {
            

            return vendormachine;
        }));
}

deleteallmachines(mformid: any, myuserid: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/deleteallmachines`, { mformid: mformid, myuserid: myuserid} ,{
        headers: myheader
      })
        .pipe(map(vendormachine => {
            

            return vendormachine;
        }));
}


deletemachine(myid: any, userid: any, machine: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/deletemachine`, { id: myid, userid: userid, machine: machine } ,{
        headers: myheader
      })
        .pipe(map(vendormachine => {
            

            return vendormachine;
        }));
}


deletespecification(myid: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/deletespecification`, { sid: myid, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendormachine => {
            

            return vendormachine;
        }));
}



deleteequipment(myid: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/deleteequipment`, { eid: myid, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendormachine => {
            

            return vendormachine;
        }));
}


Vendorstaffupdate(companyprofile: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/Vendorstaffupdate`, { companyprofile: companyprofile } ,{
        headers: myheader
      })
        .pipe(map(Vendorstaffupdate => {
            

            return Vendorstaffupdate;
        }));
}


Vendorsoftwareupdate(softwareinfo: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/Vendorsoftwareupdate`, { companyprofile: softwareinfo } ,{
        headers: myheader
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}




deletecertificates(myid: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/deletecertificates`, {id: myid, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

deletesoftware(myid: any, userid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/deletesoftware`, {id: myid, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}


addcertificate(certificateinfo: any) {
    
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<any>(`${api}/api/addcertificates`, certificateinfo  ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}

addpictures(certificateinfo: any) {
    
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<any>(`${api}/api/addpictures`, certificateinfo  ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}

deleteuploadedpicture(url: any, myuserid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/deleteuploadedpicture`, {url: url, myuserid: myuserid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

deleteuploadedbrochure(myuserid: any) {  
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${api}/api/deleteuploadedbrochure`, {myuserid: myuserid} ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            return vendorprofile;
        }));
}

addbrochure(certificateinfo: any) {
    
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<any>(`${api}/api/addbrochure`, certificateinfo  ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}


addrfqform(certificateinfo: any) {
    
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<any>(`${api}/api/addrfqform`, certificateinfo  ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}


addsoftware(software: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/addsoftware`, software  ,{
        headers: myheader
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}


switchaccount(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/switchaccount`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}

ch_approvalstat(id: any, userid: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/ch_approvalstat`, { id: id, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}

ch_rejectapproval(id: any, userid: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/ch_rejectapproval`, { id: id, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}

change_approvalstat(id: any, userid: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/change_approvalstat`, { id: id, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}

change_rejectapproval(id: any, userid: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/change_rejectapproval`, { id: id, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}

approvalstatuslist(userid: any, role: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/approvalstatuslist`, { userid: userid, role: role } ,{
        headers: myheader
      })
        .pipe(map(dashboard1 => {
            

            return dashboard1;
        }));
}

vendor_nda(userid: any, role: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/vendor_nda`, { userid: userid, role: role } ,{
        headers: myheader
      })
        .pipe(map(dashboard1 => {
            

            return dashboard1;
        }));
}

dashboard(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/dashboard`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(dashboard1 => {
            

            return dashboard1;
        }));
}

getbuyer(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/getbuyer`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(dashboard1 => {
            

            return dashboard1;
        }));
}


currentuser(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/currentuser`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(dashboard1 => {
            

            return dashboard1;
        }));
}

/*downloadNDA(userid: any){

    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(`${api}/api/downloadNDA`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(vendorprofile => {
            

            return vendorprofile;
        }));

}*/

send_invoice(formData: any) {
    
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
   //const myheader = new HttpHeaders().set('Content-Type', 'application/json');    

    return this.http.post<any>(`${api}/api/send_invoice`, formData ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}


Buyerprofileupdate(formData: any) {
    
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
   //const myheader = new HttpHeaders().set('Content-Type', 'application/json');    

    return this.http.post<any>(`${api}/api/Buyerprofileupdate`, formData ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}

chkbuyerviewer(userid: any, buyerid: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/chkbuyerviewer`, { userid: userid, buyerid: buyerid } ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}

chkmapping(vendorid: any, buyerid: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/chkmapping`, { vendorid: vendorid, buyerid: buyerid } ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}

chk_nda(userid: any, myuserid: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/chk_nda`, { userid: userid, myuserid: myuserid } ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}

chkrfqviewer(userid: any, myuserid: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/chkrfqviewer`, { userid: userid, myuserid: myuserid } ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}

chkrfqnda(userid: any, buyerid: any){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/chkrfqnda`, { userid: userid, buyerid: buyerid } ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}

rfqnda(formData: any){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<any>(`${api}/api/rfqnda`, formData ,{
        headers: headers
      })
        .pipe(map(softwareinfo => {
            

            return softwareinfo;
        }));
}


userpackageupdate(packagename: any, usertype: any, userid: any, lastprice: any, expiryDate:any, paymentType:any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/userpackageupdate`, { package: packagename, usertype: usertype, 
        userid: userid, lastprice: lastprice, expiryDate: expiryDate, paymentType: paymentType} ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}


// create RFQ shortlist 

createrfqshortlist(rfqid: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/createrfqshortlist`, { rfqid: rfqid, userid: userid} ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}


listshortlistrfq(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/listshortlistrfq`, { userid: userid} ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}


// Discount offer


checkcoupon(code: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/checkcoupon`, { code: code} ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}



vendorprofileview(userid: any, visitorid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/vendorprofileview`, { userid: userid, visitorid: visitorid} ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}

rfqviewentry(userid: any, rfqid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/rfqviewentry`, { userid: userid, myuserid: rfqid} ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}

isshortlistedrfq(userid: any, rfqid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/isshortlistedrfq`, { userid: userid, myuserid: rfqid} ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}

searchentry( userid: any, keyword: any, url: any, process: any, processvalue: any, category: any, categoryvalue: any, machine_type: any, machinevalue: any, parameter: any, parametervalue: any, search: any, rfq: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/searchentry`, { userid: userid, keyword: keyword, url: url,  process: process, processvalue: processvalue, category: category, categoryvalue: categoryvalue, machine_type: machine_type, machinevalue: machinevalue, parameter: parameter, parametervalue: parametervalue, search: search, rfq: rfq} ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}


searchlist( userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/searchlist`, { userid: userid} ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}




invoiceentry(companydiscountpercentage: any, packagename: any, usertype: any, userid: any, lastprice: any, transactionid: any,paymentType: any, 
    packageprice: any ,companydiscount: any,cgst: any ,sgst: any,igst: any,subtotal: any,tenure:any, currency: any, order_id:any, start_date:any, end_date:any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/invoiceentry`, {companydiscountpercentage:companydiscountpercentage, package: packagename, usertype: usertype, userid: userid, 
            lastprice: lastprice, transactionid: transactionid, 
            paymentType: paymentType, packageprice: packageprice ,companydiscount: companydiscount,
            cgst: cgst ,sgst: sgst,
            igst: igst,subtotal: subtotal,tenure:tenure,currency:currency,order_id:order_id,start_date:start_date, end_date:end_date } ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}


invoicelist(userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/invoicelist`, { userid: userid } ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename;
        }));
}


invoiceshow(id: any, userid: any) {
    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');


    return this.http.post<any>(`${api}/api/invoiceshow`, { id: id, userid: userid } ,{
        headers: myheader
      })
        .pipe(map(packagename => {
            

            return packagename; 
        }));
}

signup(country: string, myselcategory: string, usertype: string, company_name: string, contact_person : any, phone: any, email: any, apt: any, street: any, city: any, state: any, zip: any, gstno: any, password: any, confirm_password: any, agreeTerms: any) {
    return this.http.post<any>(`${api}/api/signup`, { country: country, myselcategory: myselcategory, usertype: usertype, company_name: company_name, contact_person: contact_person, phone: phone, 
        email:email, apt: apt, street:street, city:city, state:state, zip:zip, gstno:gstno, password: password, confirm_password:confirm_password, agreeTerms:agreeTerms })
        .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
            }

            return user;
        }));
}

admin_signup(country: string, myselcategory: string, usertype: string, company_name: string, contact_person : any, phone: any, email: any, apt: any, street: any, city: any, state: any, zip: any, gstno: any, password: any, confirm_password: any, agreeTerms: any) {
    return this.http.post<any>(`${api}/api/admin_signup`, { country: country, myselcategory: myselcategory, usertype: usertype, company_name: company_name, contact_person: contact_person, phone: phone, 
        email:email, apt: apt, street:street, city:city, state:state, zip:zip, gstno:gstno, password: password, confirm_password:confirm_password, agreeTerms:agreeTerms })
        .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
            }

            return user;
        }));
}

  register(username: string, password: string, c_password: string, company_name: string, phone: string, agree : any, apt: any, street: any, city: any, state: any, zip: any, country: any, gstno: any) {
    return this.http.post<any>(`${api}/api/buyerregisterapi`, { email: username, password: password, confirm_password: c_password, company_name: company_name, phone: phone, agree: agree, 
        apt:apt, street: street, city:city, state:state, zip:zip, country:country, gstno:gstno, user_type: 'Buyer' })
        .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
            }

            return user;
        }));
}




register1(username: string, password: string, c_password: string, company_name: string, phone: string, agree: any, apt: any, street: any, city: any, state: any, zip: any, country: any, gstno: any) {
    return this.http.post<any>(`${api}/api/buyerregisterapi`, { email: username, password: password, confirm_password: c_password, company_name: company_name, phone: phone, agree: agree, 
        apt:apt, street: street, city:city, state:state, zip:zip, country:country, gstno:gstno, user_type: 'Vendor' })
        .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
            }

            return user;
        }));
}



registervendor(username: string, password: string, c_password: string, company_name: string, phone: string) {
  return this.http.post<any>(`${api}/api/vendorregisterapi`, { email: username, password: password, confirm_password: c_password, company_name: company_name, phone: phone})
      .pipe(map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
          }

          return user;
      }));
}

registerbuyer(username: string, password: string, c_password: string, company_name: string, phone: string, address: string, membership: string, pstartdate: string, pexpirydate: string, rfq_count: string, pconfirmation: string, account_status: string, contactno: string, phoneno: string, profile_views: string, newsletter_subscription: string, user_type: string, spackage: string) {
  return this.http.post<any>(`${api}/api/buyerregisterapi`, { name: name, email: username, company_name: company_name, phone: phone, address: address, membership: membership, package_startdate: pstartdate, package_expirydate: pexpirydate, rfq_count: rfq_count, payment_confirmation: pconfirmation, account_status: account_status, contactno: contactno, phoneno: phoneno, profile_views: profile_views, newsletter_subscription: newsletter_subscription, username: username, password: password, confirm_password: c_password, user_type: user_type, package: spackage, subscription: Subscription })
      .pipe(map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
          }

          return user;
      }));
}
 
  logout() {
      // remove user from local storage to log user out
    //   localStorage.removeItem('currentUser');
      localStorage.clear();
      this.currentUserSubject.next(null);
  }





}
