<div class="banner-1 cover-image bg-background1" data-image-src="assets/images/banners/banner4.jpg">
    <login-home-navigation></login-home-navigation>
           
            <!-- <p>&nbsp;</p> -->
            <!-- <p>&nbsp;</p> -->
</div>

<section class="sptb innerpage-margin" style="background-color: white;">
    <div class="container">

        <div class="row">
        
            <div class="col-md-12 col-sm-12" style="margin-top: 5%;">
                <!--Itemsd lists-->
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h1 style="text-align: center;">Are you a Buyer?</h1>
								<div class="item7-card-desc d-flex mb-5">
									<!-- <a href="#" class="text-muted"><i class="fa fa-calendar-o mr-2"></i>Oct-18-2021</a> -->
									
									
								</div>
								<div>
								<img src="assets/images/media/strategic_sourcing.webp" alt="img" width="420px" height="100%">
								<p>GetYourVendor is a user-friendly platform for Indian and International manufacturing Buyers, so they can publish their requirements to connect with Indian and global manufacturing suppliers.</p>
								<h3><b>Who can publish requirements?</b></h3>
                                <p>	International as well as Indian Buyers,<a href="blog/Vendor-Development-strategic-sourcing"></a> Vendor Development Teams,<a href="blog/Vendor-Development-strategic-sourcing"> Sourcing Teams</a>, Purchase Teams,<a href="blog/Procurement-Management-System"> Procurement Teams</a>, <a href="blog/4-Tips-To-Optimize-Your-Supply-Chain-Cost">Supply Chain Teams</a>, On-demand manufacturing consultants, and individual manufacturing consultants can publish or publish requirements.</p>
								<h3><b>Why GetYourVendor platform?</b></h3>
                                <p>If you are looking to reduce your <a href="blog/Vendor-Development-strategic-sourcing">Vendor Development</a> cost or extend your vendor development team efforts to get suppliers; then this is the platform for you. You can publish RFQ (Request for Quotation) along with drawings, process sheets, target price, delivery date, delivery instructions, and receive multiple quotations. Our NDA (Non-Disclosure Agreement) feature will protect your data so you can publish your requirement without any fear.</p>		
                                <h3><b>What can you expect as a Buyer (Customers looking for suppliers)?</b></h3>
                                <p>As a Buyer, you can quickly find a specific supplier/vendor based on your requirements. For example, if you are looking for a supplier with HMC machines you can use our unique search feature "search by machine". You can also search your supplier/vendor by category or process. The buyer dashboard will allow you to track each RFQ, NDA, Vendor/Supplier, and final date of RFQs. Our unique vendor profile feature will allow you to complete the virtual assessment of the supplier and contact them quickly.</p>    
                                <h3><b>What does GetYourVendor platform offer?</b> &nbsp; <a href="login/signup1" ><button class="fbutton">SignUp Now</button></a></h3>  

                                    <p><li>Specific Supplier for the requirement</li></p>
                                    <p><li>Digital NDA</li></p> 
                                    <p><li>Virtual Vendor Assessment</li></p> 
                                    <p><li>Quotation Tracking</li></p>
                                    <p><li>Supplier Tracking</li> </p>
                                 <h3><b>Who are We?</b></h3>
                                 <p>We are an Indian company founded in 2020 by industry experts having International and domestic exposure to manufacturing industries. 
                                    We are based in Pune, the hub of manufacturing, and expanding our service to the globe through the global platform. 
                                    We have genuine suppliers from all over India and expanding our reach to international suppliers. </p>   
                                 <h3><b>Why it is necessary to be on the Internet or a virtual platform?</b></h3>
                                 <p>In recent times we have seen all manufacturing sectors go through difficult times due to COVID. During these times many companies had to shut down their business due to various reasons lack of manpower, lack of work, lack of sustaining funds, and lack of vision. But in many cases, companies had diversified their businesses and were aware of the Internet and its advantages. They kept going during the difficult time due to their futuristic vision to be on the Internet. 
                                    As India is growing in all sectors and making an impact on the world, govt are making sure that our Indian companies sustain as well as deliver the goods to the world and become financially independent, they have also taken steps to push all companies on the virtual world. We are in sync with the future to bring Indian companies to be on the Internet and showcase their technical ability to deliver quality goods. </p>
                                    <p>Publish your RFQ for FREE <a href="login/signup1" ><button class="fbutton">SignUp Now</button></a></p>
                                </div>
							</div>
						</div>
                    </div>
                   
                </div>
                <div class="center-block text-center">
                </div>
                <!--/Itemsd lists-->
            </div>
            <!--Right Side Content-->
         
            <!--/Right Side Content-->
        </div>
    </div>
</section><!--/Section-->

<login-home-footer></login-home-footer>