<!-- <script async src="https://www.googletagmanager.com/gtag/js?id=UA-177822249-1"></script>
  <script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-177822249-1');
  </script> -->
<div>
    <login-home-navigation></login-home-navigation>

</div>


<!--Contact-->
<section class="sptb" style="background-color: white;">
    <div class="container">
        <div class="row">
           
            <div class="col-lg-6 col-xl-6 col-md-12">
                <div class="card mb-0" style="background-color: #f5c148">
                    <div class="card-header">
                        <h3 class="card-title ">Contact Us</h3>
                    </div>
                   
                    <div class="card-body">
                        <form name="userForm" novalidate>
                        <div class="form-group" >
                            <input type="text" class="form-control" [(ngModel)]="subject" [ngModelOptions] ="{standalone :true}"  placeholder="Subject" required>
                            <!-- <label ng-show="userForm.subject.$dirty && userForm.subject.$error.required">* Required field</label> -->
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" [(ngModel)]="name" [ngModelOptions] = "{standalone :true}"  placeholder="Your Name" required>
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" [(ngModel)]="email" [ngModelOptions]="{standalone :true}"  placeholder="Email address" required>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" [(ngModel)]="phone" [ngModelOptions]="{standalone :true}"  placeholder="Phone" required>
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" [(ngModel)]="message"  [ngModelOptions]="{standalone :true}" rows="4" placeholder="Message" required></textarea>
                        </div>
                        <div class="text-right">
                            <a (click)="sendmessage()" class="btn btn-info mt-1">Send Message</a>
                        </div>
                    </form>
                    </div>
               
                </div>
            </div>
            <div class="col-lg-6 col-xl-6  col-md-12">
                <div class="card mb-0" style="background-color:rgb(17 74 102) ;">
                   
                    <div class="card-block" >

                        <div class="media" >
                            <div class="media-icon bg-info" style="height: 30px; width: 30px; margin-bottom: 2px;">
                                <i class="fe fe-mail"></i>
                            </div>
                            <div class="media-body">
                                <h4 class="card-text"><a href="mailto:contact@getyourvendor.com" style="color: white;">contact@getyourvendor.com </a></h4>
                                
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-icon bg-primary" style="height: 30px; width: 30px; margin-bottom: 2px;">
                                <i class="fe fe-phone"></i>
                            </div>
                            <div class="media-body">
                              
                                <h4 class="card-text">+91-9049000765 (Sales and Technical Support)</h4>
                               
                            </div>
                        </div>
                        
                        <div class="media">
                            <div class="media-icon bg-primary" style="height: 30px; width: 30px; margin-bottom: 2px;">
                                <i class="fe fe-phone"></i>
                            </div>
                            <div class="media-body">
                              
                                <h4 class="card-text">+91-9049000715 (For Corporate Sales)</h4>
                               
                            </div>
                        </div>
                        
                        <div class="media">
                            <div class="media-icon bg-success" style="height: 30px; width: 30px; ">
                                <i class="fe fe-map-pin" ></i>
                            </div>
                            <div class="media-body">
                                <!-- <h5 class="mt-2 font-weight-normal">Address</h5> -->
                                <h4 class="card-text"><b>INDISOURCING SOLUTIONS PVT LTD.</b><br> 
                                    Office 501, Siddharth Towers,<br> 
                                    Kothrud, 
                                    Pune-411038, Maharashtra ,<br> INDIA</h4>
                                    <!-- <br><h4 class="card-text">GSTIN-27AAFCI7399G1Z1</h4> -->
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-icon bg-success" style="height: 25px; width: 25px; margin-bottom: 2px; ">
                                <i class="fe fe-arrow-right" ></i>
                            </div>
                            <div class="media-body">
                                <!-- <h5 class="mt-2 font-weight-normal">Address</h5> -->
                                <h5 class="card-text" ><a routerLink="/faq" style="color: white;"> Have you checked our FAQ? </a></h5>
                                
                            </div>
                        </div>
                        <div class="media">
                        <a class="btn  btn-lg mx-1" href="https://www.linkedin.com/company/get-your-vendor" target="_blank" title="Follow us on LinkedIn">
                            <i class="fa fa-linkedin" style="font-size: 30px; color: white;"></i>
                          </a>

                          
                          <a class="btn btn-lg mx-1" href="https://www.facebook.com/gettyourvendor" target="_blank" title="Follow us on Facebook">
                            <i class="fa fa-facebook blue" style="font-size: 30px;color: white;"></i>
                          </a>
                    
                        
                        
                    
                          <a class="btn  btn-lg mx-1" href="https://www.instagram.com/getyourvendor/" target="_blank" title="Follow us on Instagram">
                            <i class="fa fa-instagram blue" style="font-size: 30px;color: white;"></i>
                          </a>

                          
                          <a class="btn  btn-lg mx-1" href="https://twitter.com/GetYourVendor" target="_blank" title="Follow us on Twitter">
                            <i class="fa fa-twitter blue" style="font-size: 30px;color: white;"></i>
                          </a>

                          <a class="btn  btn-lg mx-1" href="https://in.pinterest.com/getyourvendor/ " target="_blank" title="Follow us on Pinterest">
                            <i class="fa fa-pinterest blue" style="font-size: 30px;color: white;"></i>
                          </a>

                          <a class="btn  btn-lg mx-1" href="https://www.youtube.com/channel/UCqLa--YJVYZtbpiiMUhi4JQ " target="_blank" title="Subscribe our YouTube Channel">
                            <i class="fa fa-youtube blue" style="font-size: 30px;color: white;"></i>
                          </a>

                          </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /Conatct -->

<!--Map-->
<!-- <div class="map-responsive" >
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.595935699576!2d73.81623761472116!3d18.54715298739352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bfbb761c728b%3A0x62f877171448d26e!2sIndisourcing%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1607415026834!5m2!1sen!2sin" width="600" height="450" frameborder="0" 
        style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" loading="lazy"></iframe>
</div> -->
<!--/Map-->



<login-home-footer></login-home-footer>
