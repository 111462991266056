import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { first } from 'rxjs/operators';
import { User } from 'src/app/model/user.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'login-home-footer',
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.scss']
})
export class HomeFooterComponent implements OnInit {
  currentUser: User;
  myemail: any;

  constructor(  private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    public toastr: ToastrManager) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
     }

  ngOnInit(): void {

     
    }

    openDialog(): void {
      this.dialog.open(LeadDialog, {
        width: '500px',
      });
  
    
    }

    em(){
      let anydata: any;
      anydata = this.currentUser;

      if(this.myemail == null)
        return;
  
      this.authenticationService.subscribenow(this.myemail)
    .pipe(first())
    .subscribe(
        data => {
  
        //  //console.log(data);
          this.toastr.successToastr("Congratulations", 'Subscribe Successful !');
  
        },
        error => {
          this.toastr.successToastr("Network Error", 'Please try again later!');
           
        });
    }

}

@Component({
  selector: 'lead_dialog',
  templateUrl: 'lead_dialog.html',
  styleUrls: ['./home-footer.component.scss']
})
export class LeadDialog implements OnInit {
  leadForm: UntypedFormGroup;
  submitted = false;

  constructor(public dialogRef: MatDialogRef<LeadDialog>,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    public toastr: ToastrManager) {}

    ngOnInit() {
        this.leadForm = this.formBuilder.group({
            company_name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, Validators.minLength(10), Validators.pattern("^[0-9]*$")]],
        });
    }
  
    // convenience getter for easy access to form fields
    get f() { return this.leadForm.controls; }
  
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.leadForm.invalid) {
            return;
        }
  
        //console.log(this.f.email.value);
          this.authenticationService.create_lead(this.f.company_name.value, this.f.email.value, this.f.phone.value)
              .pipe(first())
              .subscribe(
                  data => {
                    //console.log(data);
                    this.toastr.successToastr("Thank You!", 'Our Team will call  you within 24 hours!');
                  },
                  error => {
                  });
    
        this.dialogRef.close();
         
    }
}