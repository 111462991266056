
<div class="banner-1 cover-image bg-background1" data-image-src="assets/images/banners/banner4.jpg">
    <login-home-navigation></login-home-navigation>
    <!-- <p>&nbsp;</p> -->
</div>


<section class="sptb innerpage-margin bg-white " >
    <div class="container">
        <div class="row" style="margin-top:10%;" >
            <div class="col-md-12 col-sm-12">
                <div class="card-mission ">
                
                    <h1>&#x275D;Our mission is to make India as a preferred destination for custom manufacturing by making it economical and easy for Buyer and Vendor to discover each other  &#x275E;</h1>
                
               </div>
            </div>
        </div>
        
        
    
    </div>
              
   

</section>
         

<section class="sptb innerpage-margin" style="background-color:white ;" >
    <div class="container">

        <div class="row">
        
            <div class="col-lg-12 col-xl-12 col-md-12">
                <!--Itemsd lists-->
                <div class="row">
                    <div class="col-lg-12 col-xl-12 col-md-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h2 style="text-align:center">About Us</h2>
								
								
								<img src="assets/images/media/supplychain.gif" alt="supplychain" width="50%" >
								
                                <div>With global supply chains diversifying to other nations, several big and small foreign corporations are now discovering India as a favorable destination to find their manufacturing partners or subcontractors. The government of India has been promoting schemes such as Make in India to attract foreign companies.
								                As a result of favorable policies towards foreign companies, several companies are now striving for manufacturing outsourcing to local Indian vendors to whom they can delegate the production of some of the essential components of their final product.</div>
                                <br><div> However, the question arises as to how foreign buyers and Indian B2B manufacturers can connect with each other to discuss the prospects of building long-term partnerships when it comes to supplying up to the mark components to the former.</div>
								                <br><div>Our technology-driven <a href="#" target="_blank">‘Get Your Vendor’</a> platform is designed to help your business focus more on the revenue-generating aspects of the business! It’s a user-friendly platform where registered vendors and buyers can connect, collaborate, and build a strong network. Here, you’ll also find domain expertise, ready to guide you through your sourcing process.</div>
                              <br> <div>Our passionate team lead by <a href="https://www.linkedin.com/in/shekhar-erande-getyourvendor">Mr. Shekhar Erande (Founder & CEO) </a> drives the effort of making our vendors and buyers to discover each other and provide end to end solution in custom manufacturing business.  </div>
                              <br><div class="fs-24" style="font-weight: bold ;" >&#x275D;shifting paradigm in the sourcing process&#x275E;</div>    
                        </div>
                            
                           
						</div>
                    </div>
                   
                </div>
               
            </div>
            <!--Right Side Content-->
         
            <!--/Right Side Content-->
        </div>
    </div>
</section><!--/Section-->

<section class="sptb" style="background-color: white">
<!-- Header -->

    <div class="container bg-dark" >
      <div class="col-xl-12 col-md-12 col-lg-12">
      <h2 style="text-align:center; font-weight: bold; color: white;">Meet Core Team</h2>
    </div>
  </div>

  
  <!-- Page Content -->
  <div class="container">
    <div class="row">
     
      <div class="col-lg-3 col-md-3 col-xl-3 mb-2">
        <div class="card border-0 shadow">
          <img src="assets/images/media/shekhar.webp" class="card-img-top" alt="Shekhar">
          <div class="card-body ">
            <h5 style="text-align:center" class="card-title mb-0 text-black-40">Shekhar Erande</h5>
            <div style="text-align:center" class="card-title mb-1 text-black-50">Founder & CEO</div>
            <div style="text-align:justify" class="card-text text-black-50">Shekhar is a first-generation entrepreneur who has passion to bring indian manufacturing companies to next generation dotcom world. Shekhar is the founder and the chief strategic thinker of getyourvendor.com. He has more than 2 decades of industry experience.</div>
            
          </div>
        </div>
      </div>
      <!-- Team Member 2 -->
      <div class="col-lg-3 col-md-3 col-xl-3 mb-2">
        <div class="card border-0 shadow">
          <img src="assets/images/media/gokhale.webp" class="card-img-top" alt="Girish">
          <div class="card-body">
            <h5 style="text-align:center" class="card-title mb-0 text-black-40">Girish Gokhale</h5>
            <div style="text-align:center" class="card-title mb-1 text-black-50">Sourcing Head & SME</div>
            <div style="text-align:justify" class="card-text text-black-50">Girish is highly experienced with Vendor Development, Materials Management, Alternative Sourcing, and Import Substitution. He also holds vast combined experience in Manufacturing, Lean Management, Developing Products, Meeting Export Quality, CE certification, Key Accounts Management, and Orientation. </div>
          </div>
        </div>
      </div>
      <!-- Team Member 3 -->
      <div class="col-lg-3 col-md-3 col-xl-3 mb-2">
        <div class="card border-0 shadow">
          <img src="assets/images/media/nikheel.webp" class="card-img-top" alt="Nikheel">
          <div class="card-body ">
            <h5 style="text-align:center" class="card-title mb-0 text-black-40">Nikheel Joshi</h5>
            <div style="text-align:center" class="card-title mb-1 text-black-50">Business Development Manager</div>
            <div style="text-align:justify" class="card-text text-black-50">Nikheel has more than 2 decades of business development experience in various manufacturing domains. His passion to help people makes him perfect business development manager.</div>
          </div>
        </div>
      </div>
      <!-- Team Member 4 -->
      <div class="col-lg-3 col-md-3 col-xl-3 mb-2">
        <div class="card border-0 shadow">
          <img src="assets/images/media/sheetal.webp" class="card-img-top" alt="Sheetal">
          <div class="card-body ">
            <h5 style="text-align:center" class="card-title mb-0 text-black-40">Sheetal Gadhari</h5>
            <div style="text-align:center" class="card-title mb-1 text-black-50">Technical Lead</div>
            <div style="text-align:justify" class="card-text text-black-50">Sheetal, is an avid Web Developer with experience in PHP, MySQL, Angular, Laravel,and numerous web technologies and frameworks. She brings invaluable experience in solving a variety of technical problems to keep any application up and running. New challenges keep her energized.</div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.row -->
  
  </div>
  <!-- /.container -->

</section>


                                                               

<login-home-footer></login-home-footer>

