<div>
    <login-home-navigation></login-home-navigation>
</div>


<!--section-->
<section class="sptb innerpage-margin" style="background-color: white;">
    <div class="container">
        
        <div class="row">
        
            <div class="col-lg-12 mt-4">
            <div class="row">
            <div class="col-md-12 inner-title-text-color">
                <h2 style="margin-bottom:20px">THE WEBSITES DISCLAIMER/TERMS & CONDITIONS/AGREEMENT</h2>
                </div>
                    <div class="col-md-12 f-15 text-justify">
                        <p>Your use of the Platform and services and tools are governed by the following terms and conditions ("Terms of Use") as applicable to the Platform including the applicable policies which are incorporated herein by way of reference. This is applicable for your use of <strong> www.getyourvendor.com (the “website” – GYV)</strong> which promotes the business between suppliers & buyers. If you transact on the Platform, You shall be subject to the policies that are applicable to the Platform for such transaction. By mere use of the Platform, You shall be contracting with Indisourcing Solutions Private Limited (Company which is the owner of the website getyourvendor.com) and these terms and conditions including the policies constitute your binding obligations, with Indisourcing.
                        </p>
                        <p> The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.</p>

                        <p> ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. IF YOU DO NOT AGREE TO OR DO NOT WISH TO BE BOUND BY THE AGREEMENT, YOU MAY NOT ACCESS OR OTHERWISE USE THE WEB SITE IN ANY MANNER.</p>

                    </div>
                    
                    
                </div>
            </div>
            
        </div>


        <div class="row">
            <div class="col-lg-12 mt-4">
                
                <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Website<br> </h3>
                        
                    <div class="row">
                    
                        <div class="col-md-12 f-15 text-justify">
                            <p>The Web Site acts merely as a match-making platform for User(s) to negotiate and interact with other User(s) for entering into negotiations in respect thereof for sale or supply of goods or services. Getyourvendor.com are not parties to any negotiations between the User(s) of the Web Site and are further not parties to any concluded between the User(s) of the Web Site. Further Indisourcing/Getyourvendor.com does not guarantee any business to any seller/vendor/user(s).</p>
                            <p>It does not control and is not liable in respect of or responsible for the quality, safety, genuineness, lawfulness or availability of the products or services offered for sale  on the Web Site or the ability of the User(s) selling or supplying the goods or services to complete a sale or the ability of User(s) purchasing goods or services to complete a purchase. This agreement shall not be deemed to create any partnership, joint venture, or any other joint business relationship between the Company and any other party.<p>
                        </div>
                        
                        
                    </div>
                </div>
                
            </div>
            <div class="row">
				<div class="col-lg-12 mt-4">
					
					<h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">	Your Account<br> </h3>
							
						<div class="row">
						
							<div class="col-md-12 f-15 text-justify">
								<p>If you use the website, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer to prevent unauthorized access to your account. You agree to accept responsibility for all activities that occur under your account or password. GYV reserve the right to refuse access to the website, terminate accounts, remove or edit content at any time without notice to you.</p> 

							</div>
							
							
						</div>
					</div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mt-4">
                            
                            <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">User(s) Eligibility<br> </h3>
                                    
                                <div class="row">
                                
                                    <div class="col-md-12 f-15 text-justify">
                                        <p>User(s) represent and warrant that they have the right to avail or use the services provided by Indisourcing, including but limited to the Web Site or any other services provided by Indisourcing in relation to the use of the Web Site. These Services can only be availed by those individuals or business entities, including sole proprietorship firms, companies and partnerships, which are authorised under applicable law to form legally binding agreements. As such, natural persons below 18 years of age and business entities or organisations that are not authorised by law to operate in India or other countries are not authorised to avail or use these Services.
        
                                        </p> 
                                        <p>User(s) agree to abide by the Agreement and any other rules and regulations imposed by the applicable law from time to time.  Indisourcing or the website shall have no liability to the User(s) or anyone else for any content, information or any other material transmitted over Indisourcing’s Services, including any fraudulent, untrue, misleading, inaccurate, defamatory, offensive or illicit material and that the risk of damage from such material rests entirely with each User(s).The user shall do its own due diligence before entering into any transaction with other users on the website. Indisourcing at it’s sole discretion reserves the right to refuse Indisourcing’s Services to anyone at any time. Indisourcing’s Services are not available and may not be availed or used by User(s) whose Accounts have been temporarily or indefinitely suspended by Indisourcing.</p>
        
                                    </div>
                                    
                                    
                                </div>
                            </div>
                            
                        </div>		
                        <div class="row">
                            <div class="col-lg-12 mt-4">
                                
                                <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">User(s) Agreement<br> </h3>
                                        
                                    <div class="row">
                                    
                                        <div class="col-md-12 f-15 text-justify">
                                            <p>This Agreement applies to any person who accesses or uses the Web Site or uses Indisourcing Services for any purpose.  It also applies to any legal entity which may be represented by any person who accesses or uses the Web Site, under actual or apparent authority. User(s) may use this Web Site and/or Indisourcing Services solely for their commercial/business purposes.
            
                                             </p> 
                                             <p>This Agreement applies to all services offered on the Web Site and by Indisourcing, collectively with any additional terms and conditions that may be applicable in respect of any specific service used or accessed by User(s) on the Web Site. In the event of any conflict or inconsistency between any provision of this Agreement and any additional terms and conditions applicable in respect of any service offered on the Web Site, such additional terms and conditions applicable in respect of that service shall prevail over this Agreement.</p>
            
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                                
                            </div>	
                            <div class="row">
                                <div class="col-lg-12 mt-4">
                                    
                                    <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Amendment to User(s) Agreement<br> </h3>
                                            
                                        <div class="row">
                                        
                                            <div class="col-md-12 f-15 text-justify">
                                                <p>Indisourcing reserves the right to change, modify, amend, or update the Agreement from time to time and such amended provisions of the Agreement shall be effective immediately upon being posted on the Web Site. If you do not agree to such provisions, You must stop using the service with immediate effect. Your continuous use of the service will be deemed to signify your acceptance of the amended provisions of the Agreement.
                                                </p> 
                                                <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Intellectual Property Rights<br> </h3>
                                                 <p>Unless otherwise indicated, Indisourcing is the sole owner of the Web & its content & has lawful license of all the rights related to the Web Site and of all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by the Company or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights.
                                                </p>
                
                                                <p>Except as expressly provided in these Terms of Use, no part of the Site & no content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission and unless agreed between the parties.
                                                </p>
                                                <p>All rights, not otherwise claimed under this Agreement by Indisourcing, are hereby reserved. Any information or advertisements contained on, distributed through, or linked, downloaded or accessed from any of the services contained on the Web Site or any offer displayed on or in connection with any service offered on the Web Site ("Website Information") is intended, solely to provide general information for the personal use of the User(s), who fully accept any and all responsibility and liabilities arising from and out of the use of such Information. Indisourcing does not represent, warrant or endorse in any manner the accuracy or reliability of Website Information, or the quality of any products and/or services obtained by the User(s) as a result of any Website Information.</p>
                
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 mt-4">
                                        
                                        <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Warranties & Limitations of Liability/Things you can’t Sue Us For<br> </h3>
                                                
                                            <div class="row">
                                            
                                                <div class="col-md-12 f-15 text-justify">
                                                    <p><strong>Sellers & Buyers registered on the Website:</strong><br> We provide the venue; the marketplaces are produced, listed, and sold directly by independent sellers, so we cannot & does not make any warranties about their quality, safety or even their legality. Any legal claim related to an item you purchase must be brought directly against the seller of the item. You release us from any claim related to the sellers/items listed on our website, including for defective items, misrepresentation by sellers, or items that caused physical injury.
                    
                                                    </p> 
                                                     <p><strong>Content to access to our Website: </strong><br>We will do our utmost to ensure that availability of the website will be uninterrupted and that transmissions will be error-free. However, due to the nature of the Internet, this cannot be guaranteed. Also, your access to the website may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services at any time without prior notice. We will attempt to limit the frequency and duration of any such suspension or restriction.
                    
                                                    </p> 

                                                    <p><strong>Claims against Objectionable Content:</strong> Because our website lists millions of sellers & their products for on the website and hosts many thousands of comments, it is not possible for us to be aware of the contents of each product listed for sale, or each comment or review that is displayed. If you believe that any content on the website is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libelous, obscene, pornographic, pedophilic or menacing; ethnically objectionable, disparaging; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of India or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, (" Objectionable Content "), please notify us immediately and will make all reasonable endeavors to remove such Objectionable Content complained about within a reasonable time.</p>
                    
                    
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        
                                    </div>	
                                    <div class="row">
                                        <div class="col-lg-12 mt-4">
                                            
                                            <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Privacy<br> </h3>
                                                    
                                                <div class="row">
                                                
                                                    <div class="col-md-12 f-15 text-justify">
                                                        <p>The personal information / data provided to us by you during the course of usage of our site will be treated as strictly confidential and in accordance with the Privacy Notice and applicable laws and regulations. If you object to your information being transferred or used, please do not use the website.
                        
                                                         </p> 
                        
                        
                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12 mt-4">
                                                
                                                <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">	Products<br> </h3>
                                                        
                                                    <div class="row">
                                                    
                                                        <div class="col-md-12 f-15 text-justify">
                                                            <p>We make every effort to display as accurately as possible the colors, features, specifications, and details of the products available on the Site. However, we do not guarantee that the colors, features, specifications, and details of the products will be accurate, complete, reliable, current, or free of other errors, and your electronic display may not accurately reflect the actual colors and details of the products.
                            
                                                            </p> 
                            
                                                            <p>All products are subject to availability & it’s the responsibility of individual seller and we cannot guarantee that items will be in stock. We reserve the right to discontinue any products at any time for any reason.  
                            
                                                            </p> 
                            
                            
                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                
                                            </div>	
                                            <div class="row">
                                                <div class="col-lg-12 mt-4">
                                                    
                                                    <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Prohibited Activities<br> </h3>
                                                            
                                                        <div class="row">
                                                        
                                                            <div class="col-md-12 f-15 text-justify">
                                                                <p>You may not access or use the Site for any purpose other than that for which we make the Site available.  
                                
                                
                                
                                </p> 
                                
                                <p>As a user of the Site, you agree not to:</p>
                                <p>1.	systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</p>
                                <p>2.	make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</p>
                                <p>3.	Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein.</p>
                                <p>4.	engage in unauthorized framing of or linking to the Site.</p>
                                <p>5.	trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;</p>
                                <p>6.	make improper use of our support services or submit false reports of abuse or misconduct.</p>
                                <p>7.	engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</p>
                                <p>8.	interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site.</p>
                                <p>9.	sell or otherwise transfer your profile.</p>
                                <p>10.	use any information obtained from the Site in order to harass, abuse, or harm another person.</p>
                                <p>11.	use the Site as part of any effort to compete with us.</p>
                                <p>12.	decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site.</p>
                                <p>13.	attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.</p>
                                <p>14.	harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you.</p>
                                <p>15.	delete the copyright or other proprietary rights notice from any Content.</p>
                                <p>16.	copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</p>
                                <p>17.	upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site.</p>
                                <p>18.	except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software.</p>



                                    

							</div>
							
							
						</div>
					</div>
					
                </div>		
                
                <div class="row">
                    <div class="col-lg-12 mt-4">
                        
                        <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Submissions<br> </h3>
                                
                            <div class="row">
                            
                                <div class="col-md-12 f-15 text-justify">
                                    <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site ("Submissions") provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you. 
    
                                     </p> 
    
                                     <p>You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.
    
                                    </p> 
    
    
    
                                </div>
                                
                                
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mt-4">
                            
                            <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Modifications & Interruptions<br> </h3>
                                    
                                <div class="row">
                                
                                    <div class="col-md-12 f-15 text-justify">
                                        <p>We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time.
        
                                        </p> 
        
                                         <p>We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site. 
        
                                        </p> 
        
                                         <p>We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. 
        
                                        </p> 
        
                                         <p>You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.
        
                                        </p> 
        
        
                                    </div>
                                    
                                    
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-lg-12 mt-4">
                                
                                <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Indemnity & Release<br> </h3>
                                        
                                    <div class="row">
                                    
                                        <div class="col-md-12 f-15 text-justify">
                                            <p>You shall indemnify and hold harmless Indisourcing Solutions Private Limited, its subsidiaries, affiliates and their respective officers, directors, agents and employees, from any claim or demand, or actions including reasonable attorney's fees, made by any third party or penalty imposed due to or arising out of your breach of these Conditions of Use or any document incorporated by reference, or your violation of any law, rules, regulations or the rights of a third party.
            
                                            </p> 
            
                                             <p>You hereby expressly release Indisourcing Solutions Private Limited and/or its affiliates and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions/inactions of the vendors, buyers and specifically waiver any claims or demands that you may have in this behalf under any statute, contract or otherwise.
            
                                             </p> 
            
            
            
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                                
                            </div>	
                            <div class="row">
                                <div class="col-lg-12 mt-4">
                                    
                                    <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Disclaimer<br> </h3>
                                            
                                        <div class="row">
                                        
                                            <div class="col-md-12 f-15 text-justify">
                                                <p>You acknowledge and undertake that you are accessing the services on the website and transacting at your own risk and are using your best and prudent judgment before entering into any transactions through the website. We shall neither be liable nor responsible for any actions or inactions of sellers &  buyers nor any breach of conditions, representations or warranties by the sellers or manufacturers of the products and hereby expressly disclaim and any all responsibility and liability in that regard. We shall not mediate or resolve any dispute or disagreement between buyers and the sellers or manufacturers of the products.
                
                                                </p> 
                
                                                <p>We further expressly disclaim any warranties or representations (express or implied) in respect of quality, suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability, fitness for a particular purpose, or legality of the products listed or displayed or transacted or the content (including product or pricing information and/or specifications) on the website. While we have taken precautions to avoid inaccuracies in content, this website, all content, information (including the price of products), software, products, services and related graphics are provided as is, without warranty of any kind. We do not implicitly or explicitly support or endorse the sale or purchase of any products on the website. At no time shall any right, title or interest in the products sold through or displayed on the website vest with Indisorcing nor shall Indiscourcing have any obligations or liabilities in respect of any transactions on the website.
                                                
                                                </p> 
                                                <p>Under no circumstances shall Indisourcing be held liable for any delay or failure or disruption of the content or services delivered through the Web Site resulting directly or indirectly from acts of nature, forces or causes beyond its reasonable control, including without limitation, Internet failures, computer, telecommunications or any other equipment failures, electrical power failures, strikes, labour disputes, riots, insurrections, civil disturbances, shortages of labour or materials, fires, flood, storms, explosions, Acts of God, natural calamities, war, governmental actions, orders of domestic or foreign courts or tribunals or non-performance of third parties.
                                                
                                                </p>
                                                
                
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row">
				                <div class="col-lg-12 mt-4">
					
					            <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Selling<br> </h3>
							
						        <div class="row">
						
							    <div class="col-md-12 f-15 text-justify">
								<p>Under no circumstances shall Indisourcing be held liable for any delay or failure or disruption of the content or services delivered through the Web Site resulting directly or indirectly from acts of nature, forces or causes beyond its reasonable control, including without limitation, Internet failures, computer, telecommunications or any other equipment failures, electrical power failures, strikes, labour disputes, riots, insurrections, civil disturbances, shortages of labour or materials, fires, flood, storms, explosions, Acts of God, natural calamities, war, governmental actions, orders of domestic or foreign courts or tribunals or non-performance of third parties. 
                                </p> 




							</div>
							
							
						</div>
					</div>
					
                </div>	
                <div class="row">
                    <div class="col-lg-12 mt-4">
                        
                        <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Links to Third Party Sites<br> </h3>
                                
                            <div class="row">
                            
                                <div class="col-md-12 f-15 text-justify">
                                    <p>Links to third party sites are provided on Web Site as a convenience to User(s). User(s) acknowledge and agree that Indisourcing does not have any control over the content of such websites and/ or any information, resources or materials provided therein.
    
     
                                    </p> 
    
                                     <p>Indisourcing may allow User(s) access to content, products or services offered by third parties through hyperlinks (in the form of word link, banners, channels or otherwise) to the websites offered by such third parties ("Third Party Websites"). Indisourcing advises its User(s) to read the terms and conditions of use and/or privacy policies applicable in respect of such Third Party Websites prior to using or accessing such Third Party Websites. Users acknowledge and agree that Indisourcing has no control over any content offered on Third Party Websites, does not monitor such Third Party Websites, and shall in no manner be deemed to be liable or responsible to any person for such Third Party Sites, or any content, products or services made available thereof.
    
     
                                    </p> 
    
    
    
    
                                </div>
                                
                                
                            </div>
                        </div>
                        
                    </div>	
                    <div class="row">
                        <div class="col-lg-12 mt-4">
                            
                            <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Registered User(s)<br> </h3>
                                    
                                <div class="row">
                                
                                    <div class="col-md-12 f-15 text-justify">
                                        <p>To become a registered User(s) of the Web Site a proper procedure has been made available on the Web Site which is for the convenience of User(s) so that they can easily use the website.
        
         
                                         </p> 
        
                                         <p>User(s) can become registered user(s) by filing an on-line Input Form by providing requisite Subscription Fees which will be valid for the period of 12 months. The same can be renewed for further period of 12 months. User(s) further agrees that once subscribed, the membership cannot be terminated before the period of 12 months & subscription money will be <b>non-refundable deposit money & under no circumstances the user(s) will demand the same.</b>
        
         
                                         </p> 
        
        
                                    </div>
                                    
                                    
                                </div>
                            </div>
                            
                        </div>	
                        <div class="row">
                            <div class="col-lg-12 mt-4">
                                
                                <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Data Protection<br> </h3>
                                        
                                    <div class="row">
                                    
                                        <div class="col-md-12 f-15 text-justify">
                                            <p>Personal information supplied by User(s) during the use of the Web Site is governed by Indisourcing’s privacy policy ("Privacy Policy"). Please click here to know about the Privacy Policy.
            
             
                                             </p> 
            
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                                
                            </div>	
                            <div class="row">
				            <div class="col-lg-12 mt-4">
					
					        <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Notices<br> </h3>
							
						    <div class="row">
						
							<div class="col-md-12 f-15 text-justify">
								<p>All notices or demands to or upon Indisourcing shall be effective if in writing and shall be deemed to be duly made when sent to Indisourcing to Indisourcing Solutions Private Limited, Office 501, Siddharth Towers, Kothrud, Pune-411038, Maharashtra, India.

 
                                </p> 

                                <p>All notices or demands to or upon a User(s) shall be effective if either delivered personally, sent by courier, certified mail, by facsimile or email to the last-known correspondence, fax or email address provided by the User(s) on the Web Site, or by posting such notice or demand on an area of the Web Site that is publicly accessible without a charge.</p> 



							</div>
							
							
						</div>
					</div>
					
                </div>	
                <div class="row">
                    <div class="col-lg-12 mt-4">
                        
                        <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Governing Law & Jurisdiction<br> </h3>
                                
                            <div class="row">
                            
                                <div class="col-md-12 f-15 text-justify">
                                    <p>These conditions/ Terms of Use/ Agreement Shall be governed by and construed in accordance with the laws of India. The parties to this Agreement hereby submit to the exclusive jurisdiction of the courts of Pune, Maharashtra, India.
    
     
                                </p> 
    
                                </div>
                                
                                
                            </div>
                        </div>
                        
                    </div>	
                    <div class="row">
                        <div class="col-lg-12 mt-4">
                            
                            <h3 class=" dark-grey-text mt-4 mb-2 f-16" style="margin-top:0px!important">Miscellaneous<br> </h3>
                                    
                                <div class="row">
                                
                                    <div class="col-md-12 f-15 text-justify">
                                    <ol type="a">
                                        <li>a. Headings for any section of the Agreement are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section.</li>
                                        <li>b. If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck out and the remaining provisions of the Agreement shall be enforced.</li>
                                         <li>c. All calls to Indisourcing are completely confidential. However, Your call may be recorded to ensure quality of service. Further, for training purpose and to ensure excellent customer service, calls from I Indisourcing may be monitored and recorded.</li>
                                        <li>d. Any complaints or concerns with regards to any content on Indisourcing’s Services or any breach of this Agreement or Privacy Policy shall be in writing at Indisourcing Solutions Private Limited, Praniti Apartments, 10, Sadhu Vasvani Nagar, Aundh, Pune-411007,Maharashtra, India or through an email signed with the electronic signature sent to contact&getyourvendor.com.</li>
                                         <li>The Agreement and the Privacy Policy constitute the entire agreement between the User(s) and Indisourcing with respect to access to and use of the Web Site, superseding any prior written or oral agreements in relation to the same subject matter herein.</li>
                                     </ol>
        
        
        
        
                                    </div>
                                    
                                    
                                </div>
                            </div>
                            
                        </div>					
        
        
                       
                        
                        
                        
                    </div>
                </section><!--/Section-->
    
                

<login-home-footer></login-home-footer>